import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useAuthorization } from 'src/hooks/use-authorization';

const Title = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  margin-bottom: 3px;
`;

const Message = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
`;

const Cta = styled.a`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-top: 10px;
  text-decoration: none;

  &,
  &:active,
  &:focus,
  &:visited,
  &:hover {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

const WarningIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.secondaryTopaz};
    }
  }
`;

const StyledSmsResourceNotLinkedBanner = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryTopaz60};
  box-shadow: 0 5px 10px 0 rgba(114, 125, 142, 0.06);
  margin: 15px 20px;
  padding: 15px;
`;

const SUPPORT_EMAIL = 'support@leadgogo.com';

export const SmsResourceNotLinkedBanner = memo(() => {
  const { t } = useTranslation(['smsChannel']);
  const { hasPermission } = useAuthorization();
  const isManager = hasPermission('company.manage');

  return (
    <StyledSmsResourceNotLinkedBanner data-lgg-id="sms-resource-not-linked-banner">
      <FlexRow>
        <WarningIcon type="infoAlertRedIcon" />
        <FlexColumn>
          <Title>{t('smsChannel:campaignRegistry.notVerifiedMessages.title')}</Title>
          <Message>
            {isManager
              ? t('smsChannel:campaignRegistry.notVerifiedMessages.message.manager')
              : t('smsChannel:campaignRegistry.notVerifiedMessages.message.agent')}
          </Message>
          {isManager && (
            <Cta href={`mailto:${SUPPORT_EMAIL}`}>
              {t('smsChannel:campaignRegistry.notVerifiedMessages.cta')}
            </Cta>
          )}
        </FlexColumn>
      </FlexRow>
    </StyledSmsResourceNotLinkedBanner>
  );
});
