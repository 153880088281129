import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dropdown } from 'antd';
import { FormProvider } from 'antd/lib/form/context';
import { range } from 'lodash';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import * as zod from 'zod';
import { addMonths, subMonths } from '@lgg/isomorphic/utils/date-operations';
import {
  BirthdayInputDateType,
  matchInputBirthdayTypeExhaustive,
  RelativeBirthdayInputDateType,
} from '@lgg/isomorphic/utils/match-birthday-input';
import {
  CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT,
  CustomDatePickerInputPlaceholder,
  CustomDatePickerPopoverFooter,
  CustomDatePickerSelect,
  CustomPickerDatePreview,
  InputContainer,
} from 'src/components/general/inputs/custom-date-pickers/shared';
import { GenericInput } from 'src/components/general/inputs/generic-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { BottomDrawer } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';

const maxDaysInMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const StyledForm = styled.form`
  width: 100%;

  ${up('md')} {
    width: 389px;
  }
`;

const FormContent = styled(FlexColumn)`
  position: relative;
`;

const BodyContainer = styled(FlexColumn)`
  min-height: 165px;
  padding: 20px;
  width: 100%;

  ${up('md')} {
    min-height: unset;
    padding: 15px 10px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const innerFormDefaultValues = {
  type: null,
  exactMonth: null,
  exactDay: null,
};

type BirthdayPickerProps = {
  label: string;
  testId?: string;
  values: {
    type: BirthdayInputDateType | null;
    exactMonth: number | null;
    exactDay: number | null;
  };
  resetInnerForm?: boolean;
  onChange: (
    props:
      | {
          type: '_isExactDay';
          exactMonth: number;
          exactDay: number;
        }
      | {
          type: '_isExactMonth';
          exactMonth: number;
        }
      | {
          type: RelativeBirthdayInputDateType;
        }
      | {
          type: null;
        },
  ) => void;
};

export const BirthdayPicker = ({
  label,
  testId,
  values,
  resetInnerForm = false,
  onChange,
}: BirthdayPickerProps) => {
  const { type, exactMonth, exactDay } = values;
  const { visible: isPanelVisible, close: closePanel, show: showPanel } = useVisible();
  const { formatDate } = useFormatDate();
  const breakpointUpMd = useBreakpoint(up('md'));
  const { t } = useTranslation(['common']);
  const {
    addDays,
    addWeeks,
    endOfMonth,
    endOfWeek,
    getNowDateAsInTimezone,
    startOfMonth,
    startOfWeek,
    subDays,
    subWeeks,
  } = useDateHelpers('UTC');
  const [pickerCurrentType, setPickerCurrentType] =
    useState<BirthdayInputDateType | null>(type ?? null);
  const [pickerCurrentMonth, setPickerCurrentMonth] = useState<number | null>(
    exactMonth ?? null,
  );
  const [pickerCurrentDay, setPickerCurrentDay] = useState<number | null>(
    exactDay ?? null,
  );

  const formatPreviewDate = useCallback(
    (date: Date) => formatDate(date, CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT, false),
    [formatDate],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = useMemo(() => getNowDateAsInTimezone(), []);

  const schema = useMemo(() => {
    return zod.object({
      type: zod
        .union([
          zod.literal('_isToday'),
          zod.literal('_isTomorrow'),
          zod.literal('_isYesterday'),
          zod.literal('_isThisWeek'),
          zod.literal('_isThisMonth'),
          zod.literal('_isLastWeek'),
          zod.literal('_isLastMonth'),
          zod.literal('_isNextWeek'),
          zod.literal('_isNextMonth'),
          zod.literal('_isExactDay'),
          zod.literal('_isExactMonth'),
        ])
        .nullish(),
      exactMonth: zod.number().nullish(),
      exactDay: zod.number().nullish(),
    });
  }, []);
  type FormValues = zod.infer<typeof schema>;

  const formInitialValues = {
    type,
    exactMonth,
    exactDay,
  };

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: innerFormDefaultValues,
    values: formInitialValues,
    shouldFocusError: true,
  });
  const [innerFormValues, setInnerFormValues] = useState<FormValues>(formInitialValues);

  useEffect(() => {
    const formSubscription = form.watch((value) => {
      setInnerFormValues(value);
    });

    return () => formSubscription.unsubscribe();
  }, [form, form.watch]);

  useEffect(() => {
    if (resetInnerForm) {
      form.reset();

      setInnerFormValues({
        type,
        exactDay,
        exactMonth,
      });
      setPickerCurrentType(type ?? null);
    }
  }, [exactDay, exactMonth, form, resetInnerForm, type]);

  const getMonthLabel = useCallback(
    (index: number) => {
      return [
        t('common:months.january'),
        t('common:months.february'),
        t('common:months.march'),
        t('common:months.april'),
        t('common:months.may'),
        t('common:months.june'),
        t('common:months.july'),
        t('common:months.august'),
        t('common:months.september'),
        t('common:months.october'),
        t('common:months.november'),
        t('common:months.december'),
      ][index - 1];
    },
    [t],
  );

  const inputValue = useMemo(() => {
    if (!pickerCurrentType) {
      return (
        <CustomDatePickerInputPlaceholder>
          {t('common:advancedDateFilter.placeholder')}
        </CustomDatePickerInputPlaceholder>
      );
    }

    return matchInputBirthdayTypeExhaustive<React.ReactElement>(pickerCurrentType, {
      today: () => <Trans i18nKey="common:birthdayFilter.values.today" />,
      tomorrow: () => <Trans i18nKey="common:birthdayFilter.values.tomorrow" />,
      yesterday: () => <Trans i18nKey="common:birthdayFilter.values.yesterday" />,
      thisWeek: () => <Trans i18nKey="common:birthdayFilter.values.thisWeek" />,
      thisMonth: () => <Trans i18nKey="common:birthdayFilter.values.thisMonth" />,
      lastWeek: () => <Trans i18nKey="common:birthdayFilter.values.lastWeek" />,
      lastMonth: () => <Trans i18nKey="common:birthdayFilter.values.lastMonth" />,
      nextWeek: () => <Trans i18nKey="common:birthdayFilter.values.nextWeek" />,
      nextMonth: () => <Trans i18nKey="common:birthdayFilter.values.nextMonth" />,
      exactDay: () =>
        pickerCurrentMonth && pickerCurrentDay ? (
          <Trans
            i18nKey="common:birthdayFilter.values.exactDay"
            values={{
              value: `${getMonthLabel(pickerCurrentMonth)} ${pickerCurrentDay}`,
            }}
          />
        ) : (
          <></>
        ),
      exactMonth: () =>
        pickerCurrentMonth ? (
          <Trans
            i18nKey="common:birthdayFilter.values.exactMonth"
            values={{
              value: getMonthLabel(pickerCurrentMonth),
            }}
          />
        ) : (
          <></>
        ),
    });
  }, [getMonthLabel, pickerCurrentDay, pickerCurrentMonth, pickerCurrentType, t]);

  const input = useMemo(
    () => (
      <GenericInput
        active={isPanelVisible}
        label={label}
        onClick={showPanel}
        value={inputValue}
        data-lgg-id={`${testId}-input`}
      />
    ),
    [inputValue, isPanelVisible, label, showPanel, testId],
  );

  const options: {
    label: string;
    value: BirthdayInputDateType;
  }[] = useMemo(
    () => [
      { label: t('common:birthdayFilter.options.today'), value: '_isToday' },
      { label: t('common:birthdayFilter.options.tomorrow'), value: '_isTomorrow' },
      { label: t('common:birthdayFilter.options.yesterday'), value: '_isYesterday' },
      { label: t('common:birthdayFilter.options.thisWeek'), value: '_isThisWeek' },
      { label: t('common:birthdayFilter.options.thisMonth'), value: '_isThisMonth' },
      { label: t('common:birthdayFilter.options.lastWeek'), value: '_isLastWeek' },
      { label: t('common:birthdayFilter.options.lastMonth'), value: '_isLastMonth' },
      { label: t('common:birthdayFilter.options.nextWeek'), value: '_isNextWeek' },
      { label: t('common:birthdayFilter.options.nextMonth'), value: '_isNextMonth' },
      { label: t('common:birthdayFilter.options.exactDay'), value: '_isExactDay' },
      { label: t('common:birthdayFilter.options.exactMonth'), value: '_isExactMonth' },
    ],
    [t],
  );

  const monthOptions: {
    label: string;
    value: number;
  }[] = useMemo(() => {
    return range(0, 11).map((index) => {
      const monthValue = index + 1;

      return {
        label: getMonthLabel(monthValue),
        value: monthValue,
      };
    });
  }, [getMonthLabel]);

  const dayOptions: {
    label: string;
    value: number;
  }[] = useMemo(() => {
    const { exactMonth } = innerFormValues;

    if (!exactMonth || !maxDaysInMonths[exactMonth - 1]) {
      return [];
    }

    return range(maxDaysInMonths[exactMonth - 1]).map((day) => {
      const dayValue = day + 1;

      return {
        label: dayValue.toString(),
        value: dayValue,
      };
    });
  }, [innerFormValues]);

  const exactMonthFormController = useMemo(
    () => (
      <Controller
        control={form.control}
        name="exactMonth"
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => {
          const selectedOption =
            monthOptions.find(({ value: optionValue }) => optionValue === field.value) ??
            null;

          return (
            <CustomDatePickerSelect
              options={monthOptions}
              isSearchable={false}
              name="birthday-exact-month-select"
              mobileLabel={t('common:filters.selectOption')}
              isClearable={false}
              isMulti={false}
              label=""
              forceCaret
              placeholder={t('common:birthdayFilter.inputPlaceholders.selectMonth')}
              hasError={Boolean(fieldState.error)}
              onChange={(option) => {
                if (option?.value) {
                  form.setValue('exactMonth', option.value);
                  form.setValue('exactDay', null);
                }
              }}
              value={selectedOption}
            />
          );
        }}
      />
    ),
    [form, monthOptions, t],
  );

  const exactDayFormController = useMemo(
    () => (
      <Controller
        control={form.control}
        name="exactDay"
        rules={{
          required: true,
          min: 1,
        }}
        render={({ field, fieldState }) => {
          const selectedOption =
            dayOptions.find(({ value: optionValue }) => optionValue === field.value) ??
            null;

          return (
            <CustomDatePickerSelect
              options={dayOptions}
              isSearchable={false}
              name="birthday-exact-day-select"
              mobileLabel={t('common:filters.selectOption')}
              isClearable={false}
              isMulti={false}
              label=""
              forceCaret
              placeholder={t('common:birthdayFilter.inputPlaceholders.selectDay')}
              hasError={Boolean(fieldState.error)}
              onChange={(option) => {
                if (option?.value) {
                  form.setValue('exactDay', option.value);
                }
              }}
              value={selectedOption}
            />
          );
        }}
      />
    ),
    [dayOptions, form, t],
  );

  const inputTypeContent = useMemo(() => {
    const { type: formType } = innerFormValues;
    if (!formType) return null;

    return matchInputBirthdayTypeExhaustive(formType, {
      today: () => null,
      tomorrow: () => null,
      yesterday: () => null,
      thisWeek: () => null,
      thisMonth: () => null,
      lastWeek: () => null,
      lastMonth: () => null,
      nextWeek: () => null,
      nextMonth: () => null,
      exactDay: () => (
        <>
          {exactMonthFormController}
          {exactDayFormController}
        </>
      ),
      exactMonth: () => exactMonthFormController,
    });
  }, [exactDayFormController, exactMonthFormController, innerFormValues]);

  const onSubmit = useCallback(() => {
    const {
      exactDay: formExactDay,
      exactMonth: formExactMonth,
      type: formType,
    } = innerFormValues;

    let hasErrors = false;

    if (!formType) {
      return null;
    }

    const defaultHandler = (type: RelativeBirthdayInputDateType) => {
      onChange({
        type,
      });
    };

    matchInputBirthdayTypeExhaustive(formType, {
      today: defaultHandler,
      tomorrow: defaultHandler,
      yesterday: defaultHandler,
      thisWeek: defaultHandler,
      thisMonth: defaultHandler,
      lastWeek: defaultHandler,
      lastMonth: defaultHandler,
      nextWeek: defaultHandler,
      nextMonth: defaultHandler,
      exactDay: () => {
        if (!formExactDay || !formExactMonth) {
          if (!formExactDay) {
            form.setError('exactDay', { type: 'required' });
          }

          if (!formExactMonth) {
            form.setError('exactMonth', { type: 'required' });
          }

          hasErrors = true;
          return null;
        }

        setPickerCurrentMonth(formExactMonth);
        setPickerCurrentDay(formExactDay);

        onChange({
          type: '_isExactDay',
          exactDay: formExactDay,
          exactMonth: formExactMonth,
        });
      },
      exactMonth: () => {
        if (formExactMonth) {
          setPickerCurrentMonth(formExactMonth);

          onChange({
            type: '_isExactMonth',
            exactMonth: formExactMonth,
          });
        } else {
          hasErrors = true;
          form.setError('exactMonth', { type: 'required' });
        }
      },
    });

    if (!hasErrors) {
      closePanel();

      setPickerCurrentType(formType);
      form.clearErrors();
    }
  }, [closePanel, form, innerFormValues, onChange]);

  const resultPreview = useMemo(() => {
    const { type: formType } = innerFormValues;

    if (!formType) return null;

    const previewText = matchInputBirthdayTypeExhaustive(formType, {
      today: () => null,
      tomorrow: () => formatPreviewDate(addDays(currentDate, 1)),
      yesterday: () => formatPreviewDate(subDays(currentDate, 1)),
      thisWeek: () =>
        `${formatPreviewDate(startOfWeek(currentDate))} - ${formatPreviewDate(
          endOfWeek(currentDate),
        )}`,
      thisMonth: () =>
        `${formatPreviewDate(startOfMonth(currentDate))} - ${formatPreviewDate(
          endOfMonth(currentDate),
        )}`,
      lastWeek: () => {
        const referenceDate = subWeeks(currentDate, 1);

        return `${formatPreviewDate(startOfWeek(referenceDate))} - ${formatPreviewDate(
          endOfWeek(referenceDate),
        )}`;
      },
      lastMonth: () => {
        const referenceDate = subMonths(currentDate, 1);

        return `${formatPreviewDate(startOfMonth(referenceDate))} - ${formatPreviewDate(
          endOfMonth(referenceDate),
        )}`;
      },
      nextWeek: () => {
        const referenceDate = addWeeks(currentDate, 1);

        return `${formatPreviewDate(startOfWeek(referenceDate))} - ${formatPreviewDate(
          endOfWeek(referenceDate),
        )}`;
      },
      nextMonth: () => {
        const referenceDate = addMonths(currentDate, 1);

        return `${formatPreviewDate(startOfMonth(referenceDate))} - ${formatPreviewDate(
          endOfMonth(referenceDate),
        )}`;
      },
      exactDay: () => null,
      exactMonth: () => null,
    });

    return previewText ? (
      <CustomPickerDatePreview
        data-lgg-id="birthday-picker-preview"
        previewText={previewText}
      />
    ) : null;
  }, [
    innerFormValues,
    formatPreviewDate,
    addDays,
    currentDate,
    subDays,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    subWeeks,
    addWeeks,
  ]);

  const formContent = useMemo(() => {
    const { type: formType } = innerFormValues;
    const selectedValue = options.find((value) => value.value === formType);

    return (
      <FormProvider {...form}>
        <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
          <FormContent data-lgg-id="birthday-picker-form">
            <BodyContainer>
              <InputContainer>
                <CustomDatePickerSelect
                  name="birthday-type-select"
                  isLoading={false}
                  value={selectedValue ?? null}
                  placeholder={t('common:filters.selectOption')}
                  mobileLabel={t('common:filters.selectOption')}
                  isSearchable={false}
                  isClearable={false}
                  forceCaret
                  onChange={(option) => {
                    form.reset();

                    if (option?.value) {
                      form.setValue('type', option.value);

                      if (option.value === '_isExactDay') {
                        form.setValue('exactDay', null);
                        form.setValue('exactMonth', null);
                      }

                      if (option.value === '_isExactMonth') {
                        form.setValue('exactMonth', null);
                      }
                    }
                  }}
                  options={options}
                />
                {inputTypeContent}
              </InputContainer>
              {!breakpointUpMd && resultPreview}
            </BodyContainer>
            <CustomDatePickerPopoverFooter
              topContent={breakpointUpMd ? resultPreview : null}
              onClear={() => {
                onChange({
                  type: null,
                });
                setInnerFormValues(innerFormDefaultValues);
                form.clearErrors();

                setPickerCurrentType(null);
                closePanel();
              }}
            />
          </FormContent>
        </StyledForm>
      </FormProvider>
    );
  }, [
    innerFormValues,
    options,
    form,
    onSubmit,
    t,
    inputTypeContent,
    breakpointUpMd,
    resultPreview,
    onChange,
    closePanel,
  ]);

  return breakpointUpMd ? (
    <DropdownContainer data-lgg-id={testId}>
      <Dropdown
        overlayClassName="context-menu"
        getPopupContainer={(triggerElement) => triggerElement}
        visible={isPanelVisible}
        trigger={['click']}
        onVisibleChange={(isVisible) => {
          if (!isVisible) {
            closePanel();
          }
        }}
        placement="bottomRight"
        overlay={formContent}
      >
        {input}
      </Dropdown>
    </DropdownContainer>
  ) : (
    <>
      {input}
      <BottomDrawer
        visible={isPanelVisible}
        title={label}
        onClose={closePanel}
        push={false}
        contentPadding={0}
      >
        {formContent}
      </BottomDrawer>
    </>
  );
};
