import { gql } from '@apollo/client';
import {
  CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS,
  CORE_ATTACHMENTS_FIELDS,
  CORE_CHAT_MESSAGES_FIELDS,
  CORE_NOTIFICATION_CONTACT_FIELDS,
  CORE_NOTIFICATION_USER_FIELDS,
} from 'src/components/providers/apollo-provider-provider';

export const GET_NOTIFICATION_FEED_ITEMS = gql`
  ${CORE_ATTACHMENTS_FIELDS}
  ${CORE_CHAT_MESSAGES_FIELDS}
  ${CORE_NOTIFICATION_CONTACT_FIELDS}
  ${CORE_NOTIFICATION_USER_FIELDS}
  ${CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS}
  query GetNotificationFeedItems(
    $where: NotificationFeedItemWhereInput
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    notificationFeedItems(
      first: $first
      after: $after
      before: $before
      last: $last
      where: $where
      orderBy: { occurredAt: DESC }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... on NotificationFeedItem {
            id
            type
            status
            occurredAt
          }
          ... on NotificationFeedItemContactAssigned {
            contact {
              ...NotificationContactFragment
            }
            assignedBy {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemContactUnassigned {
            contact {
              ...NotificationContactFragment
            }
            unassignedBy {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemContactStatusChanged {
            contact {
              ...NotificationContactFragment
            }
            oldStatus {
              id
              name
            }
            newStatus {
              id
              name
            }
            originator {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemContactUpdated {
            contact {
              ...NotificationContactFragment
            }
            originator {
              ...NotificationUserFragment
            }
            assignee {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemScheduleAssigned {
            assignee {
              ...NotificationUserFragment
            }
            originator {
              ...NotificationUserFragment
            }
            schedule {
              id
              title
              contact {
                id
              }
            }
          }
          ... on NotificationFeedItemScheduleUpdated {
            assignee {
              ...NotificationUserFragment
            }
            originator {
              ...NotificationUserFragment
            }
            schedule {
              id
              title
              contact {
                id
              }
            }
          }

          ... on NotificationFeedItemScheduleCanceled {
            assignee {
              ...NotificationUserFragment
            }
            originator {
              ...NotificationUserFragment
            }
            schedule {
              id
              title
              contact {
                id
              }
            }
          }
          ... on NotificationFeedItemScheduleCreated {
            assignee {
              ...NotificationUserFragment
            }
            originator {
              ...NotificationUserFragment
            }
            schedule {
              id
              title
              contact {
                id
              }
            }
          }
          ... on NotificationFeedItemTaskAssigned {
            task {
              id
              title
            }
            originator {
              ...NotificationUserFragment
            }
            assignee {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemTaskUpdated {
            task {
              id
              title
            }
            originator {
              ...NotificationUserFragment
            }
            assignee {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemTaskCompleted {
            task {
              id
              title
            }
            originator {
              ...NotificationUserFragment
            }
            assignee {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemTaskOverdue {
            task {
              id
              title
            }
          }
          ... on NotificationFeedItemTaskReminder {
            task {
              id
              title
              dueAt
              reminderAt
            }
            assignee {
              ...NotificationUserFragment
            }
          }
          ... on NotificationFeedItemCallMissed {
            contact {
              ...NotificationContactFragment
            }
            contactInteractionPhoneCall {
              id
              ... on ContactInteractionPhoneCall {
                id
                recording {
                  url
                }
              }
            }
          }
          ... on NotificationFeedItemContactInteractionNew {
            contact {
              ...NotificationContactFragment
            }
            contactAssignee {
              ...NotificationUserFragment
            }
            contactStage {
              name
            }
            contactInteraction {
              ... on ContactInteraction {
                id
                createdAt
                isCreationAutomatic
                participatingEntity {
                  ...ContactInteractionParticipatingEntityFragment
                }
              }
              ... on ContactInteractionWithDirection {
                direction
              }
              ... on ContactInteractionPhoneCall {
                dialStatus
                callDetail {
                  inboundLastStep
                }
                answeredPhone {
                  national
                }
                recording {
                  url
                }
              }
              ... on ContactInteractionWithAttachments {
                ...ContactInteractionAttachmentsFragment
              }
              ... on ContactInteractionChatMessage {
                ...ContactInteractionChatMessagesFragment
              }
            }
          }
          ... on NotificationFeedItemConversationNoteNew {
            contact {
              ...NotificationContactFragment
            }
            originator {
              ...NotificationUserFragment
            }
            conversationNote {
              id
              note
              conversation {
                id
              }
            }
          }
          ... on NotificationFeedItemConversationNoteMention {
            contact {
              ...NotificationContactFragment
            }
            originator {
              ...NotificationUserFragment
            }
            conversationNote {
              id
              note
              conversation {
                id
              }
            }
          }
          ... on NotificationFeedItemReportReady {
            report {
              id
              title
            }
            downloadUrl
          }
          ... on NotificationFeedItemFacebookPageAccessTokenExpired {
            pageName
          }
          ... on NotificationFeedItemFacebookPageAccessTokenMissingPermissions {
            pageName
          }
          ... on NotificationFeedItemMessageDeliveryFailurePayment {
            contactInteraction {
              ... on ContactInteraction {
                id
                createdAt
                isCreationAutomatic
                participatingEntity {
                  ...ContactInteractionParticipatingEntityFragment
                }
              }
            }
            waba
          }
        }
      }
    }
  }
`;

export const NOTIFICATION_FEED_ITEMS_QUERY_PAGE_SIZE = 100;
