import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ConversationItemsWhereInput } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { AddWhatsappTemplateOption } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/add-whatsapp-template-option';
import { ContactInteractionsReplyTabInfoMessage } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-reply-tab-messages';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const NotificationTemplateButtonContainer = styled(FlexRow)`
  align-items: center;
  cursor: pointer;
  margin: 0;

  ${up('md')} {
    margin-top: 10px;
  }
`;

const NotificationTemplateOptionIcon = styled(Icon)`
  margin-right: 4px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.secondaryTopaz};
    }
  }
`;

const NotificationTemplateOptionText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryTopaz};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

const WhatsappMessageWindowClosedSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`;

export const NotificationTemplateButton = ({ onClick }: { onClick: VoidFunction }) => {
  const breakpointUpMd = useBreakpoint(up('md'));

  return (
    <NotificationTemplateButtonContainer
      onClick={onClick}
      data-lgg-id="whatsapp-message-window-closed-add-template-option"
    >
      {breakpointUpMd ? (
        <>
          <NotificationTemplateOptionIcon type="whatsappTemplates" />
          <NotificationTemplateOptionText>
            <Trans i18nKey="conversations:messageInput.tabs.reply.messages.whatsapp.whatsappTemplate.selectMessageTemplate.desktop" />
          </NotificationTemplateOptionText>
        </>
      ) : (
        <NotificationTemplateOptionText>
          <Trans i18nKey="conversations:messageInput.tabs.reply.messages.whatsapp.whatsappTemplate.selectMessageTemplate.mobile" />
        </NotificationTemplateOptionText>
      )}
    </NotificationTemplateButtonContainer>
  );
};

export type WhatsappMessageClosedWindowContentProps = {
  isWindowOpen: Boolean;
  resourceId: string;
  resourcePhoneNumber?: string;
  contactPhoneNumber: string;
  conversationId: string;
  handleFetchCreatedItem: (where: ConversationItemsWhereInput) => void;
};

export const WhatsappMessageClosedWindowContent = ({
  isWindowOpen,
  resourceId,
  conversationId,
  resourcePhoneNumber,
  contactPhoneNumber,
  handleFetchCreatedItem,
}: WhatsappMessageClosedWindowContentProps) => {
  const { t } = useTranslation(['conversations']);
  const { getFeatureFlag } = useAuthorization();
  const breakpointUpMd = useBreakpoint(up('md'));

  const isTemplatesWorkflowActive = getFeatureFlag(
    'temp_lggdev-1353_mostrar-un-mensaje-de-ventana-de-contacto-cerrada-al-pasar-las-24-horas-y-seleccionar-el-canal-de-wh',
  );

  if (isWindowOpen) return null;

  return (
    <ContactInteractionsReplyTabInfoMessage
      lggId="whatsapp-message-window-closed-info-message"
      title={t(
        'conversations:messageInput.tabs.reply.messages.whatsapp.messageWindowClosed.title',
      )}
      body={
        isTemplatesWorkflowActive ? (
          <>
            {breakpointUpMd && (
              <WhatsappMessageWindowClosedSubtitle>
                {t(
                  'conversations:messageInput.tabs.reply.messages.whatsapp.messageWindowClosed.body',
                )}
              </WhatsappMessageWindowClosedSubtitle>
            )}
            <AddWhatsappTemplateOption
              resourceData={{
                resourceId,
                phoneNumber: resourcePhoneNumber,
              }}
              contactPhoneNumber={contactPhoneNumber}
              handleFetchCreatedItem={handleFetchCreatedItem}
              conversationId={conversationId}
              renderTrigger={({ showModal }) => (
                <NotificationTemplateButton onClick={showModal} />
              )}
            />
          </>
        ) : (
          <WhatsappMessageWindowClosedSubtitle data-lgg-id="whatsapp-message-window-closed-temp-text">
            {t(
              'conversations:messageInput.tabs.reply.messages.whatsapp.messageWindowClosed.tempMessage',
            )}
          </WhatsappMessageWindowClosedSubtitle>
        )
      }
    />
  );
};
