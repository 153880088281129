import { UserWhereInput } from '@lgg/isomorphic/types/__generated__/graphql';
import { getSortedUserSelectOptions } from 'src/components/domain/users/hooks/helpers';
import { useUsersListForSelect } from 'src/components/domain/users/hooks/use-users-list-for-select';
import { SelectOption } from 'src/components/general/inputs/select/select';

type UseUserSelectOptionsReturn = {
  loading: boolean;
  userOptions: SelectOption<number>[];
};

export const useUserSelectOptions = (
  where?: UserWhereInput,
): UseUserSelectOptionsReturn => {
  const { loading, users } = useUsersListForSelect(where);
  const userOptions = getSortedUserSelectOptions(users);

  if (loading) {
    return { loading, userOptions: [] };
  }

  return { loading, userOptions };
};
