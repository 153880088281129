import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import { match } from 'ts-pattern';
import { Query, QueryContactArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactModalDesktopView } from 'src/components/domain/contacts/contact-modal/contact-modal.desktop-view';
import { ContactModalMobileView } from 'src/components/domain/contacts/contact-modal/contact-modal.mobile-view';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { CONTACT_BLOCKING_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useModalUrl } from 'src/hooks/use-modal-url';

const GET_CONTACT_FOR_MODAL = gql`
  ${CONTACT_BLOCKING_FIELDS}
  query GetContactForModal($id: Int!) {
    contact(id: $id) {
      id
      createdAt
      firstName
      fullName
      interest
      label
      lastName
      primaryEmail
      updatedAt
      ...ContactBlockingFieldsFragment
      lastContactInteraction {
        id
        occurredAt
      }
      lastConversation {
        id
      }
      primaryPhone {
        national
        e164
      }
      stage {
        id
        name
        slug
      }
      status {
        id
        name
      }
      tags {
        id
        name
        isActive
      }
      assignee {
        id
        fullName
        avatar {
          color
          initials
        }
      }
    }
  }
`;

export const ContactModal = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { id: institutionId } = useCurrentInstitution();
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();
  const { t } = useTranslation(['errors']);
  const { goBack } = useModalUrl({
    backgroundLocation: `/i/company/${institutionId}/contacts`,
  });
  const { contactId } = useParams<{ contactId: string }>();
  const { data, loading } = useQuery<Pick<Query, 'contact'>, QueryContactArgs>(
    GET_CONTACT_FOR_MODAL,
    {
      variables: {
        id: parseInt(contactId),
      },
      onError: (error) => {
        const notFoundError = error.graphQLErrors?.find((error) => {
          const code = error.extensions?.code;

          return code?.endsWith(':NOT_FOUND');
        });

        if (notFoundError) {
          showNotification({
            type: 'warning',
            title: t('errors:resourceNotAvailable.contact.title'),
            message: t('errors:resourceNotAvailable.contact.message'),
          });
          goBack();
        } else {
          handleGraphQLError(error);
        }
      },
    },
  );

  const contact = data?.contact;

  return match(breakpointUpMd)
    .with(true, () => (
      <ContactModalDesktopView contact={contact} loading={loading} onClose={goBack} />
    ))
    .otherwise(() => (
      <ContactModalMobileView loading={loading} contact={contact} onClose={goBack} />
    ));
});
