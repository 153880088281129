import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { getSortedUserSelectOptions } from 'src/components/domain/users/hooks/helpers';
import { useUsersListForSelect } from 'src/components/domain/users/hooks/use-users-list-for-select';
import { userToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/user-to-select-option-mapper';
import { SelectOption } from 'src/components/general/inputs/select/select';

type UseUserQueryForSelectReturn =
  | { loading: true }
  | {
      loading: false;
      sortedRoles: string[];
      activeUsersByRole: { [roleId: string]: User[] };
      inactiveUsers: User[];
      users: User[];
    };

export const useUserQueryForSelect = (): UseUserQueryForSelectReturn => {
  const { loading, users } = useUsersListForSelect();

  if (loading) {
    return { loading };
  }

  const inactiveUsers = users.filter(({ isActive }) => !isActive);
  const activeUsersByRole = groupBy(
    users.filter(({ isActive }) => isActive),
    (user) => user.role.id,
  );
  const roles = Object.keys(activeUsersByRole).sort((a, b) => a.localeCompare(b));
  const companyAgentRole = roles.find((role) => role === 'company.agent');
  const companyRoles = roles
    .filter((role) => role.includes('company') && role !== 'company.agent')
    .sort((a, b) => a.localeCompare(b));
  const otherRoles = roles.filter((role) => !role.includes('company'));
  const sortedRoles = [companyAgentRole, ...companyRoles, ...otherRoles].filter(
    Boolean,
  ) as string[];

  return { loading, sortedRoles, activeUsersByRole, inactiveUsers, users };
};

type UseGroupedUserSelectOptionsReturn = {
  loading: boolean;
  usersGroups: { label: string; options: SelectOption<number>[] }[];
  users: User[];
  userOptions: SelectOption<number>[];
};

export const useGroupedUsersSelectOptions = (): UseGroupedUserSelectOptionsReturn => {
  const { t } = useTranslation(['common']);
  const result = useUserQueryForSelect();
  const { loading } = result;

  if (loading) {
    return { loading, usersGroups: [], users: [], userOptions: [] };
  }

  const { sortedRoles, activeUsersByRole, inactiveUsers, users = [] } = result;
  const usersGroups = [
    ...sortedRoles.map((role) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: t(`common:roles.${role}`) as string,
      options: activeUsersByRole[role].map(userToSelectOptionMapper),
    })),
  ];

  const userOptions = getSortedUserSelectOptions(users);

  if (inactiveUsers.length) {
    usersGroups.push({
      label: t('common:notActive'),
      options: inactiveUsers.map(userToSelectOptionMapper),
    });
  }

  return {
    loading,
    usersGroups,
    userOptions,
    users,
  };
};
