import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ConversationNote } from '@lgg/isomorphic/types/__generated__/graphql';
import { TextFormatter } from 'src/components/general/text-formatter';
import { MessageBubbleWithDirection } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { LexicalViewer } from 'src/components/pages/conversations/components/general/lexical-editor/lexical-viewer';

const Note = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  margin-bottom: 5px;
  display: block;

  &,
  .editor-paragraph {
    font-size: 13px;
    line-height: 15px;

    ${up('md')} {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.secondaryGoldDark};
  margin-bottom: 5px;
`;

type InternalNoteItemProps = {
  internalNote: ConversationNote;
  conversationId: string;
  isMentionsFeatureEnabled: boolean;
};

export const InternalNoteItem = memo<InternalNoteItemProps>(
  ({ internalNote, conversationId, isMentionsFeatureEnabled }) => {
    const { createdAt, note, id, rawNote } = internalNote;
    const { t } = useTranslation('conversations');

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-internal-note"
        direction="OUTBOUND"
        isCreationAutomatic={false}
        forceMessageCaret
        id={id}
        isNote={true}
        details={{
          interactionId: id,
          conversationId,
          message: note,
        }}
        createdAt={createdAt}
        backgroundColor="secondaryGold30"
      >
        <>
          <Title data-lgg-id="contact-interaction-internal-note-title">
            {t('contactInteractionBubble.internalNote.title')}
          </Title>
          <Note data-lgg-id="contact-interaction-internal-note-text">
            {isMentionsFeatureEnabled && rawNote ? (
              <LexicalViewer jsonString={rawNote} />
            ) : (
              <TextFormatter>{note}</TextFormatter>
            )}
          </Note>
        </>
      </MessageBubbleWithDirection>
    );
  },
);
