import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';
import { Query, QueryUserGroupsArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { useGroupedUsersSelectOptions } from 'src/components/domain/users/hooks/use-grouped-user-select-options';
import { useSetFormFieldValue } from 'src/components/filters/helpers';
import {
  EndDateFilter,
  ReactFilters,
  StartDateFilter,
} from 'src/components/filters/react-filters';
import { entityToSelectOptionMapper } from 'src/components/general/inputs/select/mappers/entity-to-select-option-mapper';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import {
  StyledTableFiltersForm,
  TableLayoutFilterProps,
  useTableSubmitHandler,
} from 'src/components/general/table-helpers';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const GET_TASK_USER_GROUPS_QUERY = gql`
  query GetTaskUserGroups($institutionId: Int!) {
    userGroups(institutionId: $institutionId, orderBy: { name: ASC }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const schema = zod.object({
  date_from: zod.string().nullish(),
  date_to: zod.string().nullish(),
  title: zod.string().optional(),
  priority: zod.string().nullish(),
  status: zod.array(zod.string()).nullish(),
  agent: zod.number().nullish(),
  group: zod.number().nullish(),
});

export type AppointmentsFiltersFormValues = zod.infer<typeof schema>;

export const defaultAppointmentsFilters: AppointmentsFiltersFormValues = {
  date_from: null,
  date_to: null,
  title: '',
  priority: '',
  status: [],
  agent: null,
  group: null,
};

export const AppointmentsFilters = memo<
  TableLayoutFilterProps<AppointmentsFiltersFormValues>
>(({ visible, filters, onClose }) => {
  const { t } = useTranslation(['common', 'appointments']);
  const { institutionId } = useParams<{ institutionId: string }>();
  const history = useHistory();
  const location = useLocation();
  const { data: userGroupsData, loading: loadingUserGroups } = useQuery<
    Pick<Query, 'userGroups'>,
    Partial<QueryUserGroupsArgs>
  >(GET_TASK_USER_GROUPS_QUERY, {
    variables: {
      institutionId: Number.parseInt(institutionId),
    },
  });
  const submitHandler = useTableSubmitHandler({ onClose });

  const userGroups = getNodesFromConnection(userGroupsData?.userGroups);
  const userGroupsOptions = userGroups.map(entityToSelectOptionMapper);

  const form = useForm<AppointmentsFiltersFormValues>({
    resolver: zodResolver(schema),
    defaultValues: filters,
  });

  const { control, handleSubmit, setValue, reset } = form;

  const { setFormFieldValue } = useSetFormFieldValue<AppointmentsFiltersFormValues>({
    defaultValues: defaultAppointmentsFilters,
    setValue,
  });

  useEffect(() => {
    if (filters) {
      reset(filters);
    }
  }, [filters, reset]);

  const {
    loading: loadingUsersData,
    usersGroups,
    userOptions,
  } = useGroupedUsersSelectOptions();

  const appointmentStatusOptions: SelectOption<string>[] = [
    {
      label: t('appointments:filters.status.options.scheduled'),
      value: 'SCHEDULED',
    },
    {
      label: t('appointments:filters.status.options.completed'),
      value: 'COMPLETED',
    },
    {
      label: t('appointments:filters.status.options.rescheduled'),
      value: 'RESCHEDULED',
    },
    {
      label: t('appointments:filters.status.options.cancelled'),
      value: 'CANCELLED',
    },
    {
      label: t('appointments:filters.status.options.noShow'),
      value: 'NO-SHOW',
    },
  ];

  const appointmentPriorityOptions: SelectOption<string>[] = [
    {
      label: t('appointments:filters.priority.options.low'),
      value: 'LOW',
    },
    {
      label: t('appointments:filters.priority.options.normal'),
      value: 'NORMAL',
    },
    {
      label: t('appointments:filters.priority.options.high'),
      value: 'HIGH',
    },
  ];

  return (
    <ReactFilters
      form={form}
      onSave={() => {
        void handleSubmit(submitHandler)();
      }}
      onReset={() => {
        onClose();
        reset(defaultAppointmentsFilters);
        history.push(location.pathname);
      }}
      filters={filters}
      visible={visible}
      onClose={onClose}
      testId="appointments-filters"
    >
      <StyledTableFiltersForm>
        <StartDateFilter
          name="date_from"
          data-lgg-id="appointments-filters-field-date-from"
          label={t('appointments:filters.startFrom.title')}
        />
        <EndDateFilter
          name="date_to"
          data-lgg-id="appointments-filters-field-date-to"
          label={t('appointments:filters.startTo.title')}
        />
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <TextInput
              data-lgg-id="appointments-filters-field-title"
              autoCapitalize="off"
              label={t('appointments:filters.title.title')}
              reserveErrorArea={false}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const selectedOptions =
              appointmentStatusOptions.filter(({ value: optionValue }) => {
                return field.value?.includes(optionValue);
              }) ?? null;

            return (
              <Select
                name="appointments-filters-field-status"
                value={selectedOptions}
                isMulti
                options={appointmentStatusOptions}
                isSearchable={false}
                label={t('appointments:filters.status.title')}
                onChange={(option) =>
                  setFormFieldValue(
                    field.name,
                    option?.map(({ value }) => `${value}`),
                  )
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name="priority"
          render={({ field }) => {
            const selectedOption =
              appointmentPriorityOptions.filter(
                ({ value: optionValue }) => optionValue === field.value,
              ) ?? null;

            return (
              <Select<SelectOption<string>>
                name="appointments-filters-field-priority"
                value={selectedOption}
                options={appointmentPriorityOptions}
                isSearchable={false}
                label={t('appointments:filters.priority.title')}
                onChange={(option) => setFormFieldValue(field.name, option?.value)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="agent"
          render={({ field }) => {
            const selectedOption = field.value
              ? userOptions.find((option) => option.value === field.value)
              : undefined;

            return (
              <Select
                name="appointments-filters-field-agent"
                value={selectedOption}
                options={usersGroups}
                isLoading={loadingUsersData}
                placeholder={t('common:selectOne')}
                isSearchable
                label={t('appointments:filters.owner.title')}
                onChange={(option) => setFormFieldValue(field.name, option?.value)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="group"
          render={({ field }) => {
            const selectedValue = field.value
              ? userGroups.find((campaign) => campaign.id === field.value)
              : undefined;

            const selectedOption = selectedValue
              ? entityToSelectOptionMapper(selectedValue)
              : null;

            return (
              <Select
                name="appointments-filters-field-account-group"
                value={selectedOption}
                options={userGroupsOptions}
                isLoading={loadingUserGroups}
                placeholder={t('common:selectOne')}
                isSearchable
                label={t('appointments:filters.accountGroup.title')}
                onChange={(option) => setFormFieldValue(field.name, option?.value)}
              />
            );
          }}
        />
      </StyledTableFiltersForm>
    </ReactFilters>
  );
});
