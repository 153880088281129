import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Contact,
  ContactInteractionUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactLabel } from 'src/components/domain/contacts/contact-label';
import { ContactStageIcon } from 'src/components/domain/contacts/contact-stage-icon';
import { LggOptionsDropdownButtonWithCustomTriggerControlled } from 'src/components/general/button/dropdown-button';
import { AssigneeLabel } from 'src/components/general/display/assignee-label';
import { Table, TableColumns, TableProps } from 'src/components/general/display/table';
import {
  MoreOptionsIcon,
  TableColumnTitle,
  TableSortData,
  getDefaultSortOrder,
} from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { ActivityOptionsBottomDrawer } from 'src/components/pages/activity/components/activity-options-bottom-drawer';
import { ActivityPopoverDetails } from 'src/components/pages/activity/components/activity-popover-details';
import { ActivityTitle } from 'src/components/pages/activity/components/activity-title';
import { getDirectionFromContactInteraction } from 'src/components/pages/conversations/components/contact-interactions/items/helpers';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const MobileContentContainer = styled(FlexRow)`
  align-items: flex-start;
  width: 100%;
`;

const MobileRowContainer = styled(FlexRow)`
  min-height: 43px;
  align-items: center;
`;

const MobileRowLeftRail = styled(FlexColumn)`
  justify-content: start;
`;

const MobileRowRightRail = styled(FlexColumn)`
  width: calc(100% - 42px);
`;

const ContactName = styled.span`
  line-height: 16px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.smalt};
  width: 100%;
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const UpperRowContainer = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ActivityDate = styled.span`
  line-height: 15px;
  margin: 0 0 4px 0;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.storm};
`;

const ActivityTime = styled.span`
  line-height: 14px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.flint};
`;

const MobileActivityDate = styled(ActivityTime)`
  text-align: right;
`;

const ActivityPopoverStyle = createGlobalStyle`
  .activity-details-popover.ant-popover-placement-topLeft,
  .activity-details-popover.ant-popover-placement-bottomLeft {
    & > .ant-popover-content > .ant-popover-arrow {
      left: 48px;
    }
  }
`;

const ActivityTitleRow = styled(FlexRow)`
  align-items: center;
`;

type ActivityTableProps = {
  interactions: ContactInteractionUnion[];
  sortData: TableSortData;
  onChange: TableProps<ContactInteractionUnion>['onChange'];
};

export const ActivityTable = memo<ActivityTableProps>(
  ({ interactions, sortData, onChange }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['activity', 'common']);
    const { formatSimpleRelativeDate, formatSimpleTime } = useFormatDate();
    const { isToday } = useDateHelpers();
    const [selectedActivity, setSelectedActivity] =
      useState<ContactInteractionUnion | null>(null);
    const { key: sortKey, direction: sortDirection } = sortData;
    const {
      getContactModalUrl,
      getContactInteractionUrl,
      getLegacyContactStatusModalUrl,
      getLegacyContactFollowUpUrl,
      getLegacyContactInteractionDepartmentModalUrl,
      getLegacyDebugCallUrl,
      getLegacyDebugNotificationUrl,
      getLegacyContactAssigneeModalUrl,
    } = useUrls();
    const mobileVisibilityHandler = useVisible();
    const { hasPermission } = useAuthorization();
    const isAdmin = hasPermission('system.admin');

    const openEditDepartmentLegacyModal = useCallback(
      (contactInteractionId: string) => {
        openRemoteModalWrapper(
          getLegacyContactInteractionDepartmentModalUrl(contactInteractionId),
        );
      },
      [getLegacyContactInteractionDepartmentModalUrl],
    );

    const getActivityPopoverDetails = useCallback(
      (activity: ContactInteractionUnion) => (
        <ActivityPopoverDetails contactInteraction={activity} />
      ),
      [],
    );

    const columns: TableColumns<ContactInteractionUnion>[] = breakpointUpMd
      ? [
          {
            title: (
              <TableColumnTitle data-lgg-id="activity-table-column-header-title">
                {t('activity:tableTitles.interaction')}
              </TableColumnTitle>
            ),
            key: 'title',
            wrapContent: true,
            showSorterTooltip: false,
            width: '100%',
            contentMinWidth: '650px',
            render: (contactInteraction: ContactInteractionUnion) => {
              return (
                <ActivityTitleRow>
                  <ActivityTitle
                    direction={
                      contactInteraction.__typename === 'ContactInteractionInPerson'
                        ? undefined
                        : getDirectionFromContactInteraction(contactInteraction)
                    }
                    contactInteraction={contactInteraction}
                    contactInteractionDetails={getActivityPopoverDetails(
                      contactInteraction,
                    )}
                  />
                </ActivityTitleRow>
              );
            },
          },
          {
            title: (
              <TableColumnTitle data-lgg-id="activity-table-column-header-contact">
                {t('activity:tableTitles.contact')}
              </TableColumnTitle>
            ),
            key: 'contact',
            dataIndex: 'contact',
            contentMinWidth: '200px',
            contentMaxWidth: '300px',
            showSorterTooltip: false,
            sorter: true,
            defaultSortOrder: getDefaultSortOrder(sortKey === 'contact', sortDirection),
            render: (contact) => (
              <ContactLabel
                contact={contact}
                testId="activity-table-cell-contact"
                marginSize={10}
              />
            ),
          },
          {
            title: (
              <TableColumnTitle data-lgg-id="activity-table-column-header-assignee">
                {t('activity:tableTitles.assignee')}
              </TableColumnTitle>
            ),
            key: 'assignee',
            dataIndex: 'contact',
            contentMinWidth: '200px',
            contentMaxWidth: '300px',
            showSorterTooltip: false,
            sorter: true,
            defaultSortOrder: getDefaultSortOrder(sortKey === 'assignee', sortDirection),
            render: (contact: Contact) => (
              <AssigneeLabel
                assignee={contact.assignee ?? undefined}
                data-lgg-id="activity-table-cell-assignee"
                onClick={() => {
                  openRemoteModalWrapper(getLegacyContactAssigneeModalUrl(contact.id));
                }}
              />
            ),
          },
          {
            title: (
              <TableColumnTitle data-lgg-id="activity-table-column-header-time">
                {t('activity:tableTitles.time')}
              </TableColumnTitle>
            ),
            key: 'occurredAt',
            dataIndex: 'occurredAt',
            contentMinWidth: '62px',
            contentMaxWidth: '62px',
            showSorterTooltip: false,
            sorter: true,
            defaultSortOrder: getDefaultSortOrder(
              sortKey === 'occurredAt',
              sortDirection,
            ),
            render: (occurredAt: string) => {
              return (
                <FlexColumn data-lgg-id="activity-table-cell-time">
                  <ActivityDate>{formatSimpleRelativeDate(occurredAt)}</ActivityDate>
                  <ActivityTime>{formatSimpleTime(occurredAt)}</ActivityTime>
                </FlexColumn>
              );
            },
          },
          {
            key: 'more-options',
            showSorterTooltip: false,
            contentMinWidth: '5px',
            contentMaxWidth: '5px',
            wrapContent: true,
            render: (_, contactInteraction) => {
              const { contact, isCreationAutomatic, __typename } = contactInteraction;
              const showDebugNotifications = isAdmin && isCreationAutomatic;
              const isCallInteraction = __typename === 'ContactInteractionPhoneCall';

              return (
                <LggOptionsDropdownButtonWithCustomTriggerControlled
                  customTrigger={
                    <MoreOptionsIcon
                      type="moreOptions"
                      lggTestId="activity-table-cell-more-options"
                    />
                  }
                  options={[
                    {
                      label: t('common:view'),
                      icon: 'magnifyingGlass',
                      'data-lgg-id': 'activity-option-view',
                      to: getContactInteractionUrl(contactInteraction.id),
                    },
                    {
                      label: t('activity:activityOptions.viewContact'),
                      icon: 'contact',
                      'data-lgg-id': 'activity-option-view-contact',
                      to: getContactModalUrl(contact.id),
                    },
                    {
                      label: t('activity:activityOptions.updateContactStatus'),
                      icon: 'status',
                      'data-lgg-id': 'activity-option-update-contact-status',
                      onClick: () => {
                        openRemoteModalWrapper(
                          getLegacyContactStatusModalUrl(contact.id),
                        );
                      },
                    },
                    {
                      label: t('activity:activityOptions.updateDepartment'),
                      icon: 'edit',
                      'data-lgg-id': 'activity-option-update-department',
                      onClick: () => {
                        openEditDepartmentLegacyModal(contactInteraction.id);
                      },
                    },
                    {
                      label: t('activity:activityOptions.followUp'),
                      icon: 'contactSetting',
                      'data-lgg-id': 'activity-option-follow-up',
                      onClick: () => {
                        openRemoteModalWrapper(getLegacyContactFollowUpUrl(contact.id));
                      },
                    },
                    ...(showDebugNotifications && isCallInteraction
                      ? [
                          {
                            label: t('activity:activityOptions.debugCall'),
                            icon: 'debug',
                            'data-lgg-id': 'activity-option-debug-call',
                            to: getLegacyDebugCallUrl(contactInteraction.id),
                          },
                        ]
                      : []),
                    ...(showDebugNotifications
                      ? [
                          {
                            label: t('activity:activityOptions.debugNotification'),
                            icon: 'debug',
                            'data-lgg-id': 'activity-option-debug-notification',
                            to: getLegacyDebugNotificationUrl(contactInteraction.id),
                          },
                        ]
                      : []),
                  ]}
                />
              );
            },
          },
        ]
      : [
          {
            showSorterTooltip: false,
            render: (_, contactInteraction) => {
              const { occurredAt, contact } = contactInteraction;

              return (
                <MobileRowContainer
                  onClick={() => {
                    setSelectedActivity(contactInteraction);
                    mobileVisibilityHandler.show();
                  }}
                >
                  <MobileContentContainer>
                    <MobileRowLeftRail>
                      <ContactStageIcon
                        slug={contact.stage.slug}
                        name={contact.status.name}
                      />
                    </MobileRowLeftRail>
                    <MobileRowRightRail>
                      <UpperRowContainer>
                        <ContactName>{contact.label}</ContactName>
                        <MobileActivityDate>
                          {isToday(occurredAt, new Date())
                            ? formatSimpleTime(occurredAt)
                            : formatSimpleRelativeDate(occurredAt)}
                        </MobileActivityDate>
                      </UpperRowContainer>
                      <ActivityTitleRow>
                        <ActivityTitle
                          direction={
                            contactInteraction.__typename === 'ContactInteractionInPerson'
                              ? undefined
                              : getDirectionFromContactInteraction(contactInteraction)
                          }
                          contactInteraction={contactInteraction}
                          contactInteractionDetails={getActivityPopoverDetails(
                            contactInteraction,
                          )}
                        />
                      </ActivityTitleRow>
                    </MobileRowRightRail>
                  </MobileContentContainer>
                </MobileRowContainer>
              );
            },
          },
        ];

    return (
      <>
        <ActivityPopoverStyle />
        <Table
          rowKey="id"
          data-lgg-id="activity-table"
          columns={columns}
          tableLayout="auto"
          loading={false}
          dataSource={interactions}
          pagination={false}
          showHeader={breakpointUpMd}
          scroll={{ x: breakpointUpMd ? 1069 : undefined }}
          onChange={onChange}
          rowClassName={(record, index) =>
            `activity-table-row activity-row-${record.id} activity-row-position-${
              index + 1
            }`
          }
        />
        {selectedActivity && (
          <ActivityOptionsBottomDrawer
            direction={getDirectionFromContactInteraction(selectedActivity)}
            onClose={mobileVisibilityHandler.close}
            selectedActivity={selectedActivity}
            details={getActivityPopoverDetails(selectedActivity)}
            visible={!breakpointUpMd && mobileVisibilityHandler.visible}
          />
        )}
      </>
    );
  },
);
