import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { gql, NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import {
  Task,
  TaskWhereInput,
  User,
  Query,
  QueryTasksArgs,
  TaskCategory,
  Mutation,
  MutationChangeTaskStatusArgs,
  TaskStatus,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactLabel } from 'src/components/domain/contacts/contact-label';
import {
  LggSelectableOptionsDropdownButton,
  LggDropdownButtonWithoutOverlay,
} from 'src/components/general/button/dropdown-button';
import { ExportResultsButton } from 'src/components/general/button/export-results-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { AssigneeLabel } from 'src/components/general/display/assignee-label';
import { GraphqlTablePagination } from 'src/components/general/display/graphql-table-pagination';
import { InAppNotification } from 'src/components/general/display/in-app-notification';
import { ItemDescription } from 'src/components/general/display/item-description';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { StatusTag } from 'src/components/general/display/status-tag';
import { Table, TableColumns } from 'src/components/general/display/table';
import { TableLayoutDate } from 'src/components/general/display/table-layout-date';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import {
  TableBodyPlaceholder,
  TableLayoutPlaceholder,
} from 'src/components/general/feedback/loading-placeholders';
import { Icon } from 'src/components/general/icon';
import {
  BasePageQueryParams,
  getDefaultSortOrder,
  getSortDirectionByOrder,
  SorterData,
  TableColumnTitle,
  tableLayoutViewButtonStyles,
  TableSortData,
} from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { TableLayoutPageContainer } from 'src/components/layout/table-layout-page-container';
import { TaskOptionsBottomDrawer } from 'src/components/pages/tasks/components/task-options-bottom-drawer';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useRefreshTask } from 'src/hooks/gql/use-refresh-task';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import {
  LEGACY_DATE_PARAM_FORMAT,
  useLegacyParamsForDashboardQuery,
  usePatchRawQueryParams,
  viewCodeQueryParamKey,
} from 'src/hooks/use-legacy-params-for-dashboard-query';
import { usePushUrlWithViewCode } from 'src/hooks/use-push-url-with-view-code';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';
import {
  defaultTasksFilters,
  TaskFilters,
  TasksFiltersFormValues,
} from './components/tasks-filters';

const GET_TASKS_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  query GetTasks(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $where: TaskWhereInput
    $orderBy: TaskOrderByInput
  ) {
    tasks(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          title
          status
          description
          dueAt
          assignedTo {
            id
            fullName
            avatar {
              color
              initials
            }
            role {
              id
              name
            }
          }
          contact {
            id
            label
            interest
            primaryEmail
            primaryPhone {
              national
              e164
            }
            tags {
              id
              name
              isActive
            }
            status {
              id
              name
            }
            stage {
              id
              name
              slug
            }
          }
          company {
            name
          }
        }
      }
    }
  }
`;

const GET_OVERDUE_TASKS_COUNT_QUERY = gql`
  query GetOverdueTasks($institutionId: Int!) {
    tasks(
      institutionId: $institutionId
      where: {
        status: { _ne: COMPLETED },
        dueAt: { _lte: "${new Date().toISOString()}" }
      }
    ) {
      totalCount
    }
  }
`;

const CHANGE_TASK_STATUS_MUTATION = gql`
  mutation ChangeTaskStatus($input: ChangeTaskStatusInput!) {
    changeTaskStatus(input: $input) {
      task {
        id
        status
      }
    }
  }
`;

const MobileTableItemMainContent = styled(FlexColumn)`
  flex: 1;
`;

const DesktopViewButton = styled(LggSelectableOptionsDropdownButton)`
  ${tableLayoutViewButtonStyles}
`;

const MobileViewButton = styled(LggDropdownButtonWithoutOverlay)`
  ${tableLayoutViewButtonStyles}
`;

const completedIconColor = css`
  fill: ${({ theme }) => theme.colors.cosmo};
`;

const DesktopStatusDropdown = styled(LggSelectableOptionsDropdownButton)`
  &[data-selected-value='COMPLETED'] {
    > span > svg path {
      ${completedIconColor};
    }
  }
`;

const MobileStatusTag = styled(StatusTag)`
  &[data-selected-value='COMPLETED'] {
    svg path {
      ${completedIconColor};
    }
  }
`;

const MobileTableItemArrowIcon = styled(Icon)`
  align-items: center;
  display: flex;
  margin-left: 10px;

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const TaskTitle = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 5px;

  ${up('md')} {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const HeaderTopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const HeaderContainer = styled(FlexColumn)`
  padding: 20px;

  ${up('md')} {
    padding: 0;
  }
`;

const HeaderBottomContainer = styled(FlexRow)`
  ${up('md')} {
    margin-bottom: 30px;
  }
`;

const HeaderInnerContainer = styled(FlexRow)`
  & > *:not(:last-child) {
    margin-right: 10px;

    ${up('md')} {
      margin-right: 20px;
    }
  }
`;

const MobileTableItemBottomContent = styled(FlexRow)`
  align-items: center;
  margin-top: 5px;

  & > * {
    min-width: max-content;
  }
`;

type TasksPageAdvancedQueryParams = Omit<TasksFiltersFormValues, 'agent' | 'group'> & {
  agent?: string;
  group?: string;
};

const filtersStateResolver = (
  queryParams: TasksPageQueryParams,
): Partial<TasksFiltersFormValues> | undefined => {
  if (!queryParams.q?.advanced) {
    return undefined;
  }

  const { advanced } = queryParams.q;
  const values: Partial<TasksFiltersFormValues> = {};

  if (advanced.due_at_from) {
    values.due_at_from = advanced.due_at_from;
  }

  if (advanced.due_at_to) {
    values.due_at_to = advanced.due_at_to;
  }

  if (advanced.title) {
    values.title = advanced.title;
  }

  if (advanced.status) {
    values.status = advanced.status;
  }

  if (advanced.category) {
    values.category = advanced.category;
  }

  if (advanced.agent) {
    values.agent = parseInt(advanced.agent);
  }

  if (advanced.group) {
    values.group = parseInt(advanced.group);
  }

  return values;
};

const sortInputResolver = (sortData: TableSortData) => {
  const { key, direction = 'DESC' } = sortData;

  switch (key) {
    case 'contact': {
      return {
        contact: {
          firstName: direction,
        },
      };
    }
    case 'assignedTo': {
      return {
        assignedTo: {
          firstName: direction,
        },
      };
    }
    default: {
      return {
        [key]: direction,
      };
    }
  }
};

const useViewCodeResolver = (viewCode: TasksPageQueryParams['view-code']) => {
  const { formatDate } = useFormatDate();
  const { getNowDateAsInTimezone } = useDateHelpers();

  const nowDate = useMemo(
    () => getNowDateAsInTimezone(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNowDateAsInTimezone, viewCode],
  );
  const now = useMemo(
    () => formatDate(nowDate, LEGACY_DATE_PARAM_FORMAT, false),
    [formatDate, nowDate],
  );

  return (rawQueryParams: TasksPageQueryParams): TasksPageQueryParams => {
    const viewCode = rawQueryParams[viewCodeQueryParamKey];
    let advanceParams: TasksPageAdvancedQueryParams = {};

    switch (viewCode) {
      case 'upcoming': {
        advanceParams = {
          status: ['YET-TO-START', 'IN-PROGRESS'],
          due_at_from: now,
        };
        break;
      }
      case 'overdue': {
        advanceParams = {
          status: ['YET-TO-START', 'IN-PROGRESS'],
          due_at_to: now,
        };
        break;
      }
      case 'completed':
        advanceParams = {
          status: ['COMPLETED'],
        };
        break;
      case 'custom':
        advanceParams = {};
        break;
    }

    if (Object.keys(advanceParams)) {
      return {
        ...rawQueryParams,
        q: {
          advanced: advanceParams,
        },
      };
    } else {
      return rawQueryParams;
    }
  };
};

export enum TaskStatusResolver {
  'YET-TO-START' = 'YET_TO_START',
  COMPLETED = 'COMPLETED',
  'IN-PROGRESS' = 'IN_PROGRESS',
}

const useWhereInputResolver = (viewCode: TasksPageQueryParams['view-code']) => {
  const { startOfDay, endOfDay, parseISO, removeTimezoneOffset } = useDateHelpers();

  return (params: TasksPageQueryParams) => {
    const whereClauses: TaskWhereInput[] = [];

    if (params.q?.advanced) {
      const { advanced } = params.q;

      if (advanced.due_at_from) {
        whereClauses.push({
          dueAt: {
            _gte:
              viewCode === 'custom'
                ? startOfDay(parseISO(advanced.due_at_from))
                : removeTimezoneOffset(parseISO(advanced.due_at_from)),
          },
        });
      }

      if (advanced.due_at_to) {
        whereClauses.push({
          dueAt: {
            _lte:
              viewCode === 'custom'
                ? endOfDay(parseISO(advanced.due_at_to))
                : removeTimezoneOffset(parseISO(advanced.due_at_to)),
          },
        });
      }

      if (advanced.title) {
        whereClauses.push({
          title: {
            _contains: advanced.title,
          },
        });
      }

      if (advanced.status) {
        whereClauses.push({
          status: {
            _in: advanced.status.map((status) => TaskStatusResolver[status]),
          },
        });
      }

      if (advanced.category) {
        whereClauses.push({
          category: {
            _eq: advanced.category as TaskCategory,
          },
        });
      }

      if (advanced.agent) {
        whereClauses.push({
          assignedTo: {
            id: {
              _eq: parseInt(advanced.agent),
            },
          },
        });
      }

      if (advanced.group) {
        whereClauses.push({
          assignedTo: {
            groupRelation: {
              id: {
                _eq: parseInt(advanced.group),
              },
            },
          },
        });
      }
    }

    return whereClauses.length ? { _and: whereClauses } : undefined;
  };
};

enum TaskViewCodeFilter {
  Upcoming = 'upcoming',
  Completed = 'completed',
  Overdue = 'overdue',
  Custom = 'custom',
}

export type TasksPageQueryParams = BasePageQueryParams<
  'upcoming' | 'completed' | 'overdue',
  TasksPageAdvancedQueryParams
>;

export const TASKS_QUERY_PAGE_SIZE = 50;

export const TasksPage = memo(() => {
  const { t } = useTranslation(['common', 'tasks']);
  useAddBreadcrumb(t('tasks:pageBreadcrumb'));
  const { id: institutionId } = useCurrentInstitution();
  const breakpointUpMd = useBreakpoint(up('md'));
  const filtersVisibility = useVisible();
  const institutionUrl = useInstitutionUrl();
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const rawQueryParams = usePatchRawQueryParams<
    TasksPageAdvancedQueryParams,
    TasksPageQueryParams['view-code']
  >({ defaultViewCode: 'upcoming' });
  const viewCode = rawQueryParams['view-code'];
  const viewCodeResolver = useViewCodeResolver(viewCode);
  const { pushUrlWithViewCode } =
    usePushUrlWithViewCode<TasksPageQueryParams['view-code']>();
  const viewCodeDropdownVisibilityHandler = useVisible(false);
  const whereInputResolver = useWhereInputResolver(viewCode);
  const {
    variables,
    sortData,
    filters,
    hasActiveFilters,
    handleSortChange,
    handleNextPage,
    handlePreviousPage,
    legacyFiltersQueryString,
  } = useLegacyParamsForDashboardQuery({
    pageSize: TASKS_QUERY_PAGE_SIZE,
    defaultFilters: defaultTasksFilters,
    dateFilterKeys: ['due_at_from', 'due_at_to'],
    defaultSortData: {
      key: 'dueAt',
      direction: 'ASC',
    },
    rawQueryParams,
    viewCodeResolver,
    whereInputResolver,
    sortInputResolver,
    filtersStateResolver,
  });

  const {
    close: closeTaskOptions,
    show: showTaskOptions,
    visible: taskOptionsVisible,
  } = useVisible();
  const { getTaskModalUrl } = useUrls();
  const history = useHistory();
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const mobileStatusOptionsVisibilityHandler = useVisible(false);
  const showNotification = useShowNotification();

  const {
    data,
    error,
    loading,
    networkStatus,
    refetch: refetchTaskList,
  } = useQuery<Pick<Query, 'tasks'>, Partial<QueryTasksArgs>>(GET_TASKS_QUERY, {
    variables: {
      ...variables,
      institutionId,
    },
  });

  const tasksIds = getNodesFromConnection(data?.tasks).map((node) => node.id as number);

  const { data: overdueTasks, refetch: refetchOverdueTasks } = useQuery<
    Pick<Query, 'tasks'>,
    Partial<QueryTasksArgs>
  >(GET_OVERDUE_TASKS_COUNT_QUERY, {
    variables: {
      institutionId,
    },
  });

  const onRefreshHandler = useMemo<UseRefreshProps>(() => {
    return {
      onRefresh: async (params) => {
        const { id, action } = params;

        if (action === 'create' || tasksIds?.includes(id)) {
          await refetchOverdueTasks();
          await refetchTaskList();
        }
      },
    };
  }, [refetchOverdueTasks, refetchTaskList, tasksIds]);

  useRefreshTask(onRefreshHandler);

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.setVariables &&
      pageContainerRef.current?.parentNode
    ) {
      (pageContainerRef.current.parentNode as HTMLDivElement).scrollTop = 0;
    }
  }, [networkStatus]);

  const [changeTaskStatus] = useMutation<
    Pick<Mutation, 'changeTaskStatus'>,
    MutationChangeTaskStatusArgs
  >(CHANGE_TASK_STATUS_MUTATION);

  const getTaskStatusSelector = useCallback(
    (task: Task) => {
      const taskStatusBaseOptions = [
        {
          value: 'YET_TO_START',
          label: t('tasks:status.yetToStart'),
          'data-lgg-id': 'task-status-option-yet-to-start',
          icon: 'taskYetToStart',
        },

        {
          value: 'IN_PROGRESS',
          label: t('tasks:status.inProgress'),
          'data-lgg-id': 'task-status-option-in-progress',
          icon: 'taskInProgress',
        },
        {
          value: 'COMPLETED',
          label: t('tasks:status.completed'),
          'data-lgg-id': 'task-status-option-completed',
          icon: 'taskCompleted',
        },
      ];

      const getTaskStatusOptions = (selectedValue: string, taskId: number) => {
        return taskStatusBaseOptions.map((statusOption) => ({
          ...statusOption,
          value: statusOption.value,
          onClick: async () => {
            await changeTaskStatus({
              variables: {
                input: {
                  institutionId,
                  taskId,
                  status: statusOption.value as TaskStatus,
                },
              },
              onCompleted: async () => {
                closeTaskOptions();
                mobileStatusOptionsVisibilityHandler.setVisible(false);

                await refetchOverdueTasks();
                await refetchTaskList();

                showNotification({
                  type: 'success',
                  title: t('tasks:tasksUpdatedMessages.success'),
                });
              },
              onError: () => {
                showNotification({
                  type: 'error',
                  title: t('tasks:tasksUpdatedMessages.error'),
                });
              },
            });
          },
        }));
      };
      const taskStatusOptions = getTaskStatusOptions(task.status, Number(task.id));
      const taskStatusData = taskStatusOptions.find(
        (option) => option.value === task.status,
      );

      if (!taskStatusData) {
        return null;
      }

      const show = () => {
        mobileStatusOptionsVisibilityHandler.show();
        setSelectedTask(task);
      };
      const close = () => {
        mobileStatusOptionsVisibilityHandler.close();
        setSelectedTask(null);
      };
      const customVisibilityHandler = {
        show,
        close,
        visible:
          selectedTask?.id === task.id && mobileStatusOptionsVisibilityHandler.visible,
        setVisible: (visible) => (visible ? show() : close()),
      };

      if (breakpointUpMd) {
        return (
          <DesktopStatusDropdown
            data-lgg-id="task-status-selector"
            options={taskStatusOptions}
            selectedValue={task.status}
            size="small"
            icon={taskStatusData.icon}
            variant="defaultGhost"
            visibilityHandler={customVisibilityHandler}
            data-selected-value={task.status}
          >
            {taskStatusData.label}
          </DesktopStatusDropdown>
        );
      }

      return (
        <>
          <MobileStatusTag
            icon={taskStatusData.icon}
            label={taskStatusData.label}
            data-selected-value={taskStatusData.value}
            onClick={customVisibilityHandler.show}
          />
          <SelectableOptionsDrawer
            visible={customVisibilityHandler.visible}
            title={t('tasks:status.title')}
            onClose={customVisibilityHandler.close}
            options={taskStatusOptions}
            selectedValue={task.status}
          />
        </>
      );
    },
    [
      t,
      breakpointUpMd,
      selectedTask?.id,
      mobileStatusOptionsVisibilityHandler,
      changeTaskStatus,
      institutionId,
      closeTaskOptions,
      refetchOverdueTasks,
      refetchTaskList,
      showNotification,
    ],
  );

  const applyOverdueStateFilter = () => {
    pushUrlWithViewCode(TaskViewCodeFilter.Overdue);
  };

  const selectedViewCodeDescription = useMemo(() => {
    let text: string;

    switch (viewCode) {
      case 'completed': {
        text = t('tasks:viewFilter.options.completed');
        break;
      }
      case 'overdue': {
        text = t('tasks:viewFilter.options.overdue');
        break;
      }
      case 'upcoming': {
        text = t('tasks:viewFilter.options.upcoming');
        break;
      }
      case 'custom': {
        text = t('common:custom');
        break;
      }
    }

    return text;
  }, [t, viewCode]);

  const isFetchingMore = networkStatus === NetworkStatus.setVariables;
  const isReFetching = networkStatus === NetworkStatus.refetch;

  if (loading && !data && !isReFetching) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  if (error) return <div>error</div>;
  if (!data) return null;

  const {
    tasks: { totalCount, pageInfo },
  } = data;

  const tasks = getNodesFromConnection(data.tasks);

  const getTaskDueDate = (task: Task) => {
    const { dueAt, status } = task;
    const isOverdue = status !== 'COMPLETED' && viewCode === TaskViewCodeFilter.Overdue;

    return (
      <TableLayoutDate
        date={dueAt}
        isOverdue={isOverdue}
        testId="tasks-table-cell-due-date"
      />
    );
  };

  const { key: sortKey, direction: sortDirection } = sortData;

  const columns: TableColumns<Task>[] = breakpointUpMd
    ? [
        {
          title: (
            <TableColumnTitle data-lgg-id="tasks-table-column-header-title">
              {t('tasks:tableTitles.task')}
            </TableColumnTitle>
          ),
          dataIndex: 'title',
          key: 'title',
          width: '100%',
          contentMinWidth: '350px',
          contentMaxWidth: '450px',
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder: getDefaultSortOrder(sortKey === 'title', sortDirection),
          render: (title: string, task) => (
            <TaskTitle
              data-lgg-id="tasks-table-cell-title"
              to={getTaskModalUrl(task.id)}
              as={Link}
            >
              {title}
            </TaskTitle>
          ),
        },
        {
          title: (
            <TableColumnTitle data-lgg-id="tasks-table-column-header-due-date">
              {t('tasks:tableTitles.dueDate')}
            </TableColumnTitle>
          ),
          dataIndex: 'dueAt',
          key: 'dueAt',
          contentMinWidth: '78px',
          contentMaxWidth: '78px',
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder: getDefaultSortOrder(sortKey === 'dueAt', sortDirection),
          render: (dueAt: Date, task: Task) => getTaskDueDate(task),
        },
        {
          title: (
            <TableColumnTitle data-lgg-id="tasks-table-column-header-contact">
              {t('tasks:tableTitles.contact')}
            </TableColumnTitle>
          ),
          dataIndex: 'contact',
          key: 'contact',
          contentMinWidth: '200px',
          contentMaxWidth: '300px',
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder: getDefaultSortOrder(sortKey === 'contact', sortDirection),
          render: (contact, record, index) => (
            <ContactLabel
              contact={contact}
              testId="tasks-table-cell-contact"
              marginSize={10}
              popoverPlacement={index === 0 ? 'bottomLeft' : 'topLeft'}
            />
          ),
        },
        {
          title: (
            <TableColumnTitle data-lgg-id="tasks-table-column-header-assignee">
              {t('tasks:tableTitles.assignee')}
            </TableColumnTitle>
          ),
          dataIndex: 'assignedTo',
          key: 'assignedTo',
          sorter: true,
          showSorterTooltip: false,
          contentMinWidth: '200px',
          contentMaxWidth: '300px',
          defaultSortOrder: getDefaultSortOrder(sortKey === 'assignedTo', sortDirection),
          render: (assignedTo: User, task) => (
            <AssigneeLabel
              assignee={assignedTo}
              data-lgg-id="tasks-table-cell-assignee"
              onClick={() => history.push(getTaskModalUrl(task.id))}
            />
          ),
        },
        {
          title: (
            <TableColumnTitle data-lgg-id="tasks-table-column-header-status">
              {t('tasks:tableTitles.status')}
            </TableColumnTitle>
          ),
          dataIndex: 'status',
          key: 'status',
          sorter: true,
          showSorterTooltip: false,
          wrapContent: true,
          contentMaxWidth: '143px',
          contentMinWidth: '143px',
          defaultSortOrder: getDefaultSortOrder(sortKey === 'status', sortDirection),
          render: (status, task) => getTaskStatusSelector(task),
        },
      ]
    : [
        {
          title: '',
          dataIndex: '',
          wrapContent: true,
          width: '100%',
          contentMaxWidth: '100%',
          key: 'title',
          render: (task: Task) => {
            const { title, assignedTo, contact } = task;
            const taskDate = getTaskDueDate(task);

            return (
              <FlexRow
                onClick={() => {
                  setSelectedTask(task);
                  showTaskOptions();
                }}
              >
                <MobileTableItemMainContent>
                  <TaskTitle>{title}</TaskTitle>
                  <ItemDescription
                    title={t('tasks:tableTitles.due')}
                    description={taskDate}
                  />
                  <ItemDescription
                    title={t('tasks:tableTitles.assignedTo')}
                    description={assignedTo?.fullName}
                  />
                  <MobileTableItemBottomContent>
                    {getTaskStatusSelector(task)}
                    {contact && (
                      <ContactLabel contact={contact} testId="tasks-table-cell-contact" />
                    )}
                  </MobileTableItemBottomContent>
                </MobileTableItemMainContent>
                <MobileTableItemArrowIcon type="arrowNextNoPadding" />
              </FlexRow>
            );
          },
        },
      ];

  const overdueNotificationsCount = overdueTasks?.tasks.totalCount ?? 0;
  const hasNotifications = overdueTasks && overdueNotificationsCount > 0;
  const taskNotification =
    viewCode !== TaskViewCodeFilter.Overdue && hasNotifications ? (
      <InAppNotification
        icon="schedule18"
        testId="task-page-overdue-notification"
        onClick={() => {
          applyOverdueStateFilter();
        }}
        title={t(`tasks:overdueNotification.${breakpointUpMd ? 'titleShort' : 'title'}`, {
          taskCount: overdueNotificationsCount.toLocaleString(),
        })}
        variant="error"
      />
    ) : null;

  const options = [
    {
      label: t('tasks:viewFilter.options.upcoming'),
      icon: '',
      'data-lgg-id': 'task-page-view-button-option-upcoming',
      value: TaskViewCodeFilter.Upcoming,
      onClick: () => {
        pushUrlWithViewCode(TaskViewCodeFilter.Upcoming);
      },
    },
    {
      label: t('tasks:viewFilter.options.completed'),
      icon: '',
      'data-lgg-id': 'task-page-view-button-option-completed',
      value: TaskViewCodeFilter.Completed,
      onClick: () => {
        pushUrlWithViewCode(TaskViewCodeFilter.Completed);
      },
    },
    {
      label: t('tasks:viewFilter.options.overdue'),
      icon: '',
      'data-lgg-id': 'task-page-view-button-option-overdue',
      value: TaskViewCodeFilter.Overdue,
      onClick: () => {
        applyOverdueStateFilter();
      },
    },
  ];

  const viewButton = breakpointUpMd ? (
    <DesktopViewButton
      visibilityHandler={viewCodeDropdownVisibilityHandler}
      data-lgg-id="task-page-view-button"
      selectedValue={viewCode}
      customDropdownProps={{
        overlayStyle: {
          minWidth: '141px',
        },
      }}
      options={options}
      variant="defaultWhite"
      size="regular"
    >
      {selectedViewCodeDescription}
    </DesktopViewButton>
  ) : (
    <>
      <MobileViewButton
        data-lgg-id="task-page-view-button"
        variant="defaultWhite"
        size="regular"
        onClick={() =>
          viewCodeDropdownVisibilityHandler.setVisible(
            !viewCodeDropdownVisibilityHandler.visible,
          )
        }
        isActive={viewCodeDropdownVisibilityHandler.visible}
      >
        {selectedViewCodeDescription}
      </MobileViewButton>
      <SelectableOptionsDrawer
        title={selectedViewCodeDescription}
        options={options}
        selectedValue={viewCode}
        onClose={viewCodeDropdownVisibilityHandler.close}
        visible={viewCodeDropdownVisibilityHandler.visible}
      />
    </>
  );

  return (
    <TableLayoutPageContainer data-lgg-id="tasks-page-container" ref={pageContainerRef}>
      <HeaderContainer>
        <HeaderTopContainer>
          <HeaderInnerContainer>
            {viewButton}
            {breakpointUpMd && taskNotification}
          </HeaderInnerContainer>
          <HeaderInnerContainer>
            <FiltersButton
              filtersVisibilityHandler={filtersVisibility}
              hasActiveFilters={hasActiveFilters}
              data-lgg-id="tasks-page-filters-button"
            />
            <ExportResultsButton
              testId="tasks-page-export"
              requestUrl={`${institutionUrl}cms/tasks/`}
              hideShareOption
              params={legacyFiltersQueryString}
            />
          </HeaderInnerContainer>
        </HeaderTopContainer>
        <HeaderBottomContainer>
          <PaginationInfo
            total={totalCount}
            entityName={t('tasks:tasks')}
            testId="tasks-page-pagination"
          />
        </HeaderBottomContainer>
      </HeaderContainer>

      {isFetchingMore ? (
        <TableBodyPlaceholder />
      ) : (
        <>
          {!breakpointUpMd && taskNotification}
          <Table
            rowKey="id"
            data-lgg-id="tasks-table"
            columns={columns}
            tableLayout="auto"
            loading={false}
            dataSource={tasks}
            pagination={false}
            showHeader={breakpointUpMd}
            onChange={(pagination, filters, sorter) => {
              const { columnKey, order } = sorter as SorterData<Task>;
              const sortDirection = getSortDirectionByOrder(order);
              handleSortChange({ columnKey, sortDirection });
            }}
            scroll={{ x: breakpointUpMd ? 1098 : undefined }}
            rowClassName={(record, index) =>
              `tasks-table-row task-row-${record.id} task-row-position-${index + 1}`
            }
          />
          <TaskOptionsBottomDrawer
            mobileStatusOptionsVisibilityHandler={mobileStatusOptionsVisibilityHandler}
            onClose={closeTaskOptions}
            selectedTask={selectedTask}
            visible={taskOptionsVisible}
          />
        </>
      )}
      <GraphqlTablePagination
        previousPageHandler={handlePreviousPage}
        nextPageHandler={handleNextPage}
        pageInfo={pageInfo}
      />
      <TaskFilters
        visible={filtersVisibility.visible}
        onClose={filtersVisibility.close}
        filters={filters}
      />
    </TableLayoutPageContainer>
  );
});
