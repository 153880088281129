import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { gql, useMutation } from '@apollo/client';
import {
  Mutation,
  MutationDeleteContactNoteArgs,
  ContactRelatedNoteUnion,
  Query,
  QueryContactRelatedNotesArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  ContactModalTabProps,
  ContactModalLoadingView,
  ContactModalTabEmptyView,
} from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-tabs.shared';
import { NoteItem } from 'src/components/domain/contacts/contact-modal/tabs/contact-notes/contact-modal-note-item';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useRefreshContactNotes } from 'src/hooks/gql/use-refresh-contact-notes';
import { UseRefreshProps } from 'src/hooks/gql/use-refresh.shared';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';
import { sortContactRelatedNotes } from './contact-related-notes/helpers/sort-contact-related-notes';

const DELETE_CONTACT_NOTE_MUTATION = gql`
  mutation DeleteContactNoteMutation($input: DeleteContactNoteInput!) {
    deleteContactNote(input: $input) {
      success
    }
  }
`;

export const GET_CONTACT_RELATED_NOTES_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  # noinspection GraphQLUnresolvedReference,GraphQLSchemaValidation
  query GetContactNotes(
    $institutionId: Int!
    $first: Int
    $after: String
    $before: String
    $last: Int
    $contactId: Float!
  ) {
    contactRelatedNotes(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
      where: { contact: { id: { _eq: $contactId } } }
      orderBy: { createdAt: DESC }
    ) @connection(key: "infinite-scroll") {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ... on ContactNote {
            id
            __typename
            note
            createdAt
            user {
              id
              fullName
            }
          }
          ... on ConversationNote {
            id
            __typename
            note
            createdAt
            rawNote
            createdBy {
              id
              fullName
            }
            conversation {
              id
            }
          }
        }
      }
    }
  }
`;

const PAGE_SIZE = 50;

type ContactModalNotesTabProps = ContactModalTabProps;

export const ContactModalNotesTab = memo<ContactModalNotesTabProps>(
  ({ contactId, virtuosoRef }) => {
    const handleGraphQLError = useHandleGraphQLError();
    const { id: companyId } = useCurrentInstitution();
    const { t } = useTranslation(['contacts']);
    const [deleteNote] = useMutation<
      Pick<Mutation, 'deleteContactNote'>,
      MutationDeleteContactNoteArgs
    >(DELETE_CONTACT_NOTE_MUTATION, {
      onError: handleGraphQLError,
    });

    const {
      handleLoadBottom,
      firstItemIndex,
      handleLoadTop,
      loadingMoreBottom,
      loading,
      nodes,
    } = useInfiniteListQuery<
      Pick<Query, 'contactRelatedNotes'>,
      Partial<QueryContactRelatedNotesArgs> & { contactId: number },
      ContactRelatedNoteUnion
    >({
      queryOptions: {
        getNodeIdCallback: (notification) => notification?.id,
        query: GET_CONTACT_RELATED_NOTES_QUERY,
        variables: {
          first: PAGE_SIZE,
          institutionId: companyId,
          contactId,
        },
        getEdgesCallback: (data) => data?.contactRelatedNotes.edges ?? [],
        getPageInfoCallback: (data) => data?.contactRelatedNotes.pageInfo,
        onError: handleGraphQLError,
        queryPageSize: PAGE_SIZE,
        sortNodesCallback: sortContactRelatedNotes,
      },
      pollingOptions: {
        interval: 30000,
      },
    });

    const onRefreshHandler = useMemo<UseRefreshProps>(() => {
      return {
        onRefresh: async (params) => {
          const { action } = params;

          if (action === 'create') {
            await handleLoadTop({ forceLoadMore: true });
          }
        },
      };
    }, [handleLoadTop]);

    useRefreshContactNotes(onRefreshHandler);

    if (loading) {
      return <ContactModalLoadingView data-lgg-id="contact-modal-notes-tab-loading" />;
    }

    if (!nodes.length) {
      return (
        <ContactModalTabEmptyView
          data-lgg-id="contact-modal-notes-tab-empty"
          title={t('contacts:detailsModal.tabs.notes.emptyList.title')}
          message={t('contacts:detailsModal.tabs.notes.emptyList.message')}
          iconConfig={{ type: 'embedded', icon: 'tabNotes' }}
        />
      );
    }

    return (
      <Virtuoso
        ref={virtuosoRef}
        data-lgg-id="contact-modal-notes-tab-list"
        data={nodes}
        endReached={() => handleLoadBottom()}
        firstItemIndex={firstItemIndex}
        components={{
          Footer: () => (
            <ListLoadingItem
              data-lgg-id="contact-modal-notes-tab-loading-more"
              visible={loadingMoreBottom}
            />
          ),
        }}
        itemContent={(index, note) => {
          return (
            <NoteItem
              note={note}
              contactId={contactId}
              onNoteDelete={({ contactId, noteId }) => {
                void deleteNote({
                  variables: {
                    input: {
                      contactId,
                      contactNoteId: noteId,
                      institutionId: companyId,
                    },
                  },
                  update(cache) {
                    const normalizedId = cache.identify({
                      id: noteId,
                      __typename: 'ContactNote',
                    });
                    cache.evict({ id: normalizedId });
                    cache.gc();
                  },
                });
              }}
            />
          );
        }}
      />
    );
  },
);
