import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Icon } from 'src/components/general/icon';

const TabMessageContainer = styled.div<{ borderColor: ColorPaletteItem }>`
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(114, 125, 142, 0.06);
  border: solid 1px ${({ borderColor, theme }) => theme.colors[borderColor]};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 11px 15px;
  margin: 15px 20px 10px;
  display: flex;

  ${up('md')} {
    margin: 15px 20px 10px;
    padding: 16px 16px 14px;
  }
`;

const TabMessageTitle = styled.p`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.smalt};
  margin-bottom: 5px;

  ${up('md')} {
    margin-bottom: 3px;
  }
`;

const TabMessageBody = styled.div`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.storm};
  margin: 0;

  ${up('md')} {
    line-height: 16px;
  }
`;

const TabMessageIcon = styled(Icon)<{ color: ColorPaletteItem }>`
  box-shadow: 0 15px 34px 0 rgba(20, 133, 158, 0.15);
  width: 18px;
  height: 18px;
  margin-right: 10px;

  svg path {
    fill: ${({ color, theme }) => theme.colors[color]};
  }
`;

type ContactInteractionsReplyTabMessageProps = {
  title: string;
  body: React.ReactNode;
  lggId: string;
};

export const ContactInteractionsReplyTabInfoMessage =
  memo<ContactInteractionsReplyTabMessageProps>(({ title, body, lggId }) => {
    return (
      <TabMessageContainer borderColor="topaz60" data-lgg-id={lggId}>
        <TabMessageIcon color="topaz" type="infoAlertIcon" />
        <div>
          <TabMessageTitle>{title}</TabMessageTitle>
          <TabMessageBody>{body}</TabMessageBody>
        </div>
      </TabMessageContainer>
    );
  });
