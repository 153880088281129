import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactInteractionInPerson } from '@lgg/isomorphic/types/__generated__/graphql';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  BubbleDivider,
  BubbleMessageHeader,
  InteractionTextContent,
  MessageBubbleWithDirection,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';

type InPersonItemProps = {
  contactInteraction: ContactInteractionInPerson;
  conversationId: string;
};

export const InPersonItem = memo<InPersonItemProps>(
  ({ contactInteraction, conversationId }) => {
    const { t } = useTranslation(['conversations']);
    const {
      isCreationAutomatic,
      id,
      description,
      occurredAt,
      source,
      campaign,
      department,
    } = contactInteraction;

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-in-person"
        isCreationAutomatic={isCreationAutomatic}
        direction="OUTBOUND"
        details={{
          interactionId: id,
          conversationId,
          source,
          campaign,
          department,
          message: description,
        }}
        createdAt={occurredAt}
      >
        <>
          <BubbleMessageHeader
            contactInteraction={contactInteraction}
            title={t('conversations:contactInteractionBubble.inPersonInteraction')}
          />
          <BubbleDivider direction="OUTBOUND" />
          {description && (
            <InteractionTextContent direction="OUTBOUND">
              <TextFormatter>{description}</TextFormatter>
            </InteractionTextContent>
          )}
        </>
      </MessageBubbleWithDirection>
    );
  },
);
