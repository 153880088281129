import { useTranslation } from 'react-i18next';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { useUserQueryForSelect } from 'src/components/domain/users/hooks/use-grouped-user-select-options';

type useActiveUsersGroupedByRoleReturn = {
  loading: boolean;
  usersGroups: { label: string; users: User[] }[];
};

export const useActiveUsersGroupedByRole = (): useActiveUsersGroupedByRoleReturn => {
  const { t } = useTranslation(['common']);
  const result = useUserQueryForSelect();
  const { loading } = result;

  if (loading) {
    return { loading, usersGroups: [] };
  }

  const { sortedRoles, activeUsersByRole } = result;

  const usersGroups = [
    ...sortedRoles.map((role) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: t(`common:roles.${role}`) as string,
      users: activeUsersByRole[role].sort((a, b) => a.fullName.localeCompare(b.fullName)),
    })),
  ];

  return {
    loading,
    usersGroups,
  };
};
