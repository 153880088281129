import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ContactInteractionUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const StyledManualInteractionIcon = styled(Icon).attrs({ type: 'manualInteraction' })`
  margin-left: 5px;
  display: inline;
`;

type ManualInteractionIconProps = {
  contactInteraction: ContactInteractionUnion;
};

export const ManualInteractionIcon = memo<ManualInteractionIconProps>(
  ({ contactInteraction }) => {
    const { t } = useTranslation(['activity']);
    const breakpointUpMd = useBreakpoint(up('md'));

    if (contactInteraction.isCreationAutomatic) {
      return null;
    }

    const icon = <StyledManualInteractionIcon lggTestId="manual-interaction-icon" />;

    return breakpointUpMd ? (
      <Tooltip title={t('activity:manualInteraction')}>{icon}</Tooltip>
    ) : (
      icon
    );
  },
);
