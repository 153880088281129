import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dropdown } from 'antd';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import * as zod from 'zod';
import { LggButton } from 'src/components/general/button/lgg-button';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { TextInput } from 'src/components/general/inputs/text-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

export const TemplateParameter = styled.span`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.casper};
  color: ${({ theme }) => theme.colors.gogo};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.courierPrimeBold};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.36px;
  line-height: 14px;
  margin: 0 2px;
  padding: 3px;
  position: relative;
  top: -2px;

  &.active,
  &.error {
    padding: 2px;
  }

  &.active {
    border: 2px solid ${({ theme }) => theme.colors.secondaryTopaz};
  }

  &.error {
    border: 2px solid ${({ theme }) => theme.colors.secondaryCoral60};
  }
`;

const EditParameterModalContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.koala};
  border-radius: 4px;
  overflow: hidden;
  width: 300px;
`;

const EditParameterTitleContainer = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 17px;
  padding: 14px 15px;
`;

const EditParameterFormContainer = styled(FlexRow)`
  flex: 1;
  padding: 20px 20px 40px;

  ${up('md')} {
    padding: 10px 15px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex: 1;
  width: 100%;
`;

const StyledSaveButton = styled(LggButton)`
  height: 34px;
  margin-left: 20px;
  width: 62px;

  ${up('md')} {
    margin-left: 10px;
    width: 58px;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 34px;
  line-height: 14px;
  padding: 10px;
  width: 100%;
`;

type WhatsappTemplateParameterProps = {
  testId: string;
  label: string;
  value?: string;
  onChange: ValueChanged<string | undefined>;
  hasError?: boolean;
};

export const WhatsappTemplateParameter = ({
  label,
  value,
  hasError,
  onChange,
  testId,
}: WhatsappTemplateParameterProps) => {
  const { t } = useTranslation(['common', 'conversations']);
  const schema = useMemo(
    () =>
      zod.object({
        value: zod.union([zod.string(), zod.undefined()]),
      }),
    [],
  );
  const { visible, setVisible } = useVisible(false);
  const breakpointUpMd = useBreakpoint(up('md'));

  type FormValues = zod.infer<typeof schema>;

  const { control, handleSubmit, setFocus, resetField } = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: {
      value: value,
    },
  });

  const templateParameter = useMemo(
    () => (
      <TemplateParameter
        data-lgg-id={testId}
        className={c({
          active: visible,
          error: hasError,
        })}
        onClick={() => {
          setVisible(true);
        }}
      >
        {value ?? label}
      </TemplateParameter>
    ),
    [hasError, label, setVisible, testId, value, visible],
  );

  const editParameterForm = useMemo(
    () => (
      <EditParameterFormContainer>
        <StyledForm
          onSubmit={handleSubmit(({ value }) => {
            const expectedValue = value ? value.trim() : value;
            setVisible(false);
            onChange(expectedValue?.length ? expectedValue : undefined);
          })}
        >
          <Controller
            control={control}
            name="value"
            render={({ field }) => (
              <StyledTextInput
                containerStyles={{
                  flex: 1,
                }}
                data-lgg-id="edit-whatsapp-parameter-text-input"
                autoFocus
                reserveErrorArea={false}
                autoComplete="off"
                autoCapitalize="off"
                label=""
                placeholder={t(
                  'conversations:messageInput.tabs.reply.messages.whatsapp.whatsappTemplate.editParameterPopover.inputPlaceholder',
                  { label },
                )}
                {...field}
              />
            )}
          />
          <StyledSaveButton
            type="submit"
            data-lgg-id="edit-whatsapp-parameter-submit-button"
          >
            {t('common:save')}
          </StyledSaveButton>
        </StyledForm>
      </EditParameterFormContainer>
    ),
    [control, handleSubmit, label, onChange, setVisible, t],
  );

  const onVisibleChangeHandler = useCallback(
    (isVisible) => {
      if (!isVisible) {
        setVisible(false);
        resetField('value');
      } else {
        // TODO: This is not working
        setFocus('value');
      }
    },
    [resetField, setFocus, setVisible],
  );

  return (
    <>
      {breakpointUpMd ? (
        <Dropdown
          arrow
          visible={visible}
          overlayClassName="edit-whatsapp-parameter-dropdown"
          trigger={['click']}
          onVisibleChange={onVisibleChangeHandler}
          overlay={
            // This should not be needed, but in the version of antd that we use, the destroyOnClose is prop is not implemented
            visible ? (
              <EditParameterModalContainer data-lgg-id="edit-whatsapp-parameter-dropdown">
                <EditParameterTitleContainer>
                  {t(
                    'conversations:messageInput.tabs.reply.messages.whatsapp.whatsappTemplate.editParameterPopover.title',
                    { label },
                  )}
                </EditParameterTitleContainer>
                {editParameterForm}
              </EditParameterModalContainer>
            ) : (
              <></>
            )
          }
        >
          {templateParameter}
        </Dropdown>
      ) : (
        <>
          {templateParameter}
          <BottomDrawer
            visible={visible}
            afterVisibleChange={onVisibleChangeHandler}
            title={t(
              'conversations:messageInput.tabs.reply.messages.whatsapp.whatsappTemplate.editParameterPopover.title',
              { label },
            )}
            onClose={() => onVisibleChangeHandler(false)}
          >
            {editParameterForm}
          </BottomDrawer>
        </>
      )}
    </>
  );
};
