import { gql, useQuery } from '@apollo/client';
import {
  Query,
  QueryUsersArgs,
  User,
  UserWhereInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const USERS_FOR_SELECT_QUERY = gql`
  query GetUsers($institutionId: Int!, $where: UserWhereInput) {
    users(institutionId: $institutionId, where: $where) {
      edges {
        node {
          id
          fullName
          isActive
          role {
            id
          }
          avatar {
            color
            initials
          }
        }
      }
    }
  }
`;

type UseUsersListForSelectReturn = {
  loading: boolean;
  users: User[];
};

export const useUsersListForSelect = (
  where?: UserWhereInput,
): UseUsersListForSelectReturn => {
  const currentInstitution = useCurrentInstitution();
  const handleGraphQlError = useHandleGraphQLError();
  const variables: Partial<QueryUsersArgs> = {
    institutionId: currentInstitution.id,
    where: where ?? { isActive: null },
  };

  const { loading, data } = useQuery<Pick<Query, 'users'>, Partial<QueryUsersArgs>>(
    USERS_FOR_SELECT_QUERY,
    {
      variables,
      onError: handleGraphQlError,
    },
  );

  if (loading) {
    return { loading, users: [] };
  }

  const users = getNodesFromConnection(data?.users);

  return { loading, users };
};
