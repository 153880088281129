import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContactInteractionFacebookAd } from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BubbleDivider,
  BubbleMessageHeader,
  MessageBubbleWithDirection,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';

const FieldLabel = styled.span`
  font-size: 13px;
  line-height: 15px;
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.smalt};
`;

const FieldValue = styled.span`
  font-size: 13px;
  line-height: 15px;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
`;

const StyledRow = styled(FlexRow)`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const FormFields = styled(FlexColumn)`
  margin-bottom: 10px;
`;

type FacebookAdFormItemProps = {
  contactInteraction: ContactInteractionFacebookAd;
  conversationId: string;
};

export const FacebookAdFormItem = memo<FacebookAdFormItemProps>(
  ({ contactInteraction, conversationId }) => {
    const { t } = useTranslation(['conversations']);
    const { isCreationAutomatic, id, occurredAt, fields, source, campaign, department } =
      contactInteraction;

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-facebook-ad"
        isCreationAutomatic={isCreationAutomatic}
        details={{
          conversationId,
          interactionId: id,
          campaign,
          source,
          department,
          message: fields.map(({ label, value }) => `${label}: ${value}`).join('\n'),
        }}
        direction="INBOUND"
        createdAt={occurredAt}
      >
        <>
          <BubbleMessageHeader
            contactInteraction={contactInteraction}
            icon="iconFacebookForm"
            title={t('conversations:contactInteractionBubble.facebookAdForm')}
          />
          <BubbleDivider direction="INBOUND" />
          <FormFields>
            {fields.map(({ label, value }) => (
              <StyledRow key={value}>
                <FieldLabel>{label}:</FieldLabel>
                <FieldValue>&nbsp;{value}</FieldValue>
              </StyledRow>
            ))}
          </FormFields>
        </>
      </MessageBubbleWithDirection>
    );
  },
);
