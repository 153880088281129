import React from 'react';
import c from 'classnames';
import styled from 'styled-components';
import { FlexRow } from 'src/components/layout/flex-row';
import { ContactInteractionInputAreaTabKey } from './contact-interactions-input-area';

const MessageTypeSelectorContainer = styled(FlexRow)`
  min-height: 35px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  align-items: center;
  justify-content: space-between;
`;

const MessageTypeSelectorOptionContainer = styled.p`
  height: 24px;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin: 0;
  color: ${({ theme }) => theme.colors.flint};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }

  :hover {
    color: ${({ theme }) => theme.colors.gogo};
  }

  &.active {
    color: ${({ theme }) => theme.colors.gogo};
    border-bottom: 2px solid ${({ theme }) => theme.colors.gogo};
  }
`;

const MessageTypeOptionsContainer = styled(FlexRow)`
  height: 100%;
  align-items: end;
  margin-bottom: -1px;
`;

type MessageTypeSelectorProps = {
  selectedTabKey: ContactInteractionInputAreaTabKey;
  onSelected: ValueChanged<ContactInteractionInputAreaTabKey>;
  extraContent: React.ReactChild | null;
};

export const MessageTypeSelector = ({
  selectedTabKey = ContactInteractionInputAreaTabKey.Reply,
  onSelected,
  extraContent = null,
}: MessageTypeSelectorProps) => {
  const options = [
    {
      label: 'Reply',
      value: ContactInteractionInputAreaTabKey.Reply,
    },
    {
      label: 'Internal Note',
      value: ContactInteractionInputAreaTabKey.InternalNote,
    },
  ];

  return (
    <MessageTypeSelectorContainer>
      <MessageTypeOptionsContainer>
        {options.map((option) => {
          return (
            <MessageTypeSelectorOptionContainer
              key={option.value}
              data-lgg-id={`contact-interaction-message-type-tab-${option.value}`}
              className={c({ active: option.value === selectedTabKey })}
              onClick={() => {
                onSelected(option.value);
              }}
            >
              {option.label}
            </MessageTypeSelectorOptionContainer>
          );
        })}
      </MessageTypeOptionsContainer>
      {extraContent}
    </MessageTypeSelectorContainer>
  );
};
