import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactInteractionUnion,
  ContactInteractionDirection,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  DrawerOption,
  OptionsBottomDrawerWithDetails,
} from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { ActivityIconWithDirection } from 'src/components/pages/activity/components/activity-icon-with-direction';
import { getActivityIcon } from 'src/components/pages/activity/helpers';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useUrls } from 'src/hooks/use-urls';

const DrawerOptionIcon = styled(Icon)`
  svg {
    width: 18px;
    height: 18px;

    path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;

type ActivityOptionsBottomDrawerProps = {
  onClose: VoidFunction;
  selectedActivity: ContactInteractionUnion;
  visible: boolean;
  details: JSX.Element;
  direction: ContactInteractionDirection;
};

export const ActivityOptionsBottomDrawer = memo<ActivityOptionsBottomDrawerProps>(
  ({ onClose, selectedActivity, details, visible, direction }) => {
    const { t } = useTranslation(['activity']);
    const breakpointUpMd = useBreakpoint(up('md'));
    const {
      getLegacyContactFollowUpUrl,
      getLegacyContactStatusModalUrl,
      getContactModalUrl,
      getLegacyDebugCallUrl,
      getLegacyDebugNotificationUrl,
      getContactInteractionUrl,
    } = useUrls();
    const history = useHistory();
    const { __typename: activityType } = selectedActivity;
    const activityIcon = getActivityIcon({
      type: activityType,
      isUnansweredCall:
        activityType === 'ContactInteractionPhoneCall' &&
        selectedActivity.dialStatus === 'UNANSWERED',
    });
    const { hasPermission } = useAuthorization();
    const isAdmin = hasPermission('system.admin');
    const { isCreationAutomatic, __typename } = selectedActivity;
    const showDebugNotifications = isAdmin && isCreationAutomatic;
    const isCallInteraction = __typename === 'ContactInteractionPhoneCall';

    const title = useMemo(() => {
      switch (selectedActivity?.__typename) {
        case 'ContactInteractionFacebookAd': {
          return t('activity:interactionDrawer.title.facebookAdForm');
        }
        case 'ContactInteractionFacebookMessenger': {
          return t('activity:interactionDrawer.title.facebookMessage');
        }
        case 'ContactInteractionEmail': {
          return t('activity:interactionDrawer.title.email');
        }
        case 'ContactInteractionInPerson': {
          return t('activity:interactionDrawer.title.inPerson');
        }
        case 'ContactInteractionPhoneCall': {
          return t('activity:interactionDrawer.title.call');
        }
        default: {
          return t('activity:interactionDrawer.title.sms');
        }
      }
    }, [selectedActivity?.__typename, t]);
    const options = useMemo(() => {
      const options: DrawerOption[] = [
        {
          onClick: () => {
            history.push(getContactInteractionUrl(selectedActivity.id));
          },
          label: t('activity:activityOptions.view'),
          icon: <DrawerOptionIcon type="interaction" />,
          'data-lgg-id': 'activity-option-view',
        },
        {
          onClick: () => {
            history.push(getContactModalUrl(selectedActivity.contact.id));
          },
          label: t('activity:activityOptions.viewContact'),
          icon: <DrawerOptionIcon type="contact" />,
          'data-lgg-id': 'activity-option-view-contact',
        },
        {
          onClick: () => {
            openRemoteModalWrapper(
              getLegacyContactStatusModalUrl(selectedActivity.contact.id),
            );
          },
          label: t('activity:activityOptions.updateContactStatus'),
          icon: <DrawerOptionIcon type="status" />,
          'data-lgg-id': 'activity-option-update-contact-status',
        },
        {
          onClick: () => {
            openRemoteModalWrapper(
              getLegacyContactFollowUpUrl(selectedActivity.contact.id),
            );
          },
          label: t('activity:activityOptions.followUp'),
          icon: <DrawerOptionIcon type="contactSetting" />,
          'data-lgg-id': 'activity-option-follow-up',
        },
        ...(showDebugNotifications && isCallInteraction
          ? [
              {
                label: t('activity:activityOptions.debugCall'),
                icon: 'debug',
                'data-lgg-id': 'activity-option-debug-call',
                onClick: () => {
                  history.push(getLegacyDebugCallUrl(selectedActivity.id));
                },
              },
            ]
          : []),
        ...(showDebugNotifications
          ? [
              {
                label: t('activity:activityOptions.debugNotification'),
                icon: 'debug',
                'data-lgg-id': 'activity-option-debug-notification',
                onClick: () => {
                  history.push(getLegacyDebugNotificationUrl(selectedActivity.id));
                },
              },
            ]
          : []),
      ];

      return options;
    }, [
      t,
      showDebugNotifications,
      isCallInteraction,
      selectedActivity.id,
      selectedActivity.contact.id,
      history,
      getContactInteractionUrl,
      getContactModalUrl,
      getLegacyContactStatusModalUrl,
      getLegacyContactFollowUpUrl,
      getLegacyDebugCallUrl,
      getLegacyDebugNotificationUrl,
    ]);

    return (
      <OptionsBottomDrawerWithDetails
        details={details}
        onClose={onClose}
        title={title}
        leading={<ActivityIconWithDirection direction={direction} icon={activityIcon} />}
        visible={!breakpointUpMd && visible}
        options={options}
      />
    );
  },
);
