import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/lib/dropdown/dropdown';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import { match } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  ContactInteractionChannelAvailabilityForContact,
  ContactInteractionChannelSlug,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { overlayShapeStyles } from 'src/theme/sub-themes/overlays-theme';

const ContextMenuStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  .ant-dropdown.channel-selection-context-menu {
    ${overlayShapeStyles};
    overflow: hidden;
  }
`;

const ContextMenuItemIcon = styled(Icon)<{ $iconColor: ColorPaletteItem }>`
  margin-right: 10px;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme, $iconColor }) => theme.colors[$iconColor]};
    }
  }
`;

const ContextMenuItemLabel = styled.p<{ isActive: boolean }>`
  font-family: ${({ theme }) => theme.font.regular};
  margin: 0;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.smalt : theme.colors.flint)};
`;

const ContextMenuItem = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 4px;
  min-height: 32px;
`;

const ContextMenuTitle = styled.p`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 13px;
  margin: 10px;
  color: ${({ theme }) => theme.colors.carbon};
`;

const ContextMenuItemContainer = styled.div`
  &.enabled {
    ${ContextMenuItem} {
      &:hover {
        background: ${({ theme }) => theme.colors.porcelain};
      }
    }
  }

  &.disabled {
    pointer-events: none;

    ${ContextMenuItemIcon} {
      svg path {
        fill: ${({ theme }) => theme.colors.flint};
      }
    }

    ${ContextMenuItemLabel} {
      color: ${({ theme }) => theme.colors.flint};
    }
  }

  &.enabled + .disabled {
    border-top: 1px solid ${({ theme }) => theme.colors.koala};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContextMenu = styled.div`
  padding: 5px;
  width: 185px;

  ${ContextMenuItemContainer} {
    &.enabled:not(:last-child) {
      margin-bottom: 3px;
    }

    &.disabled {
      padding-top: 3px;
    }
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DropdownIcon = styled(Icon)`
  transform: rotate(0deg);
  transition: all 0.3s ease;
  width: 10px;

  &.active {
    transform: rotate(180deg);
  }

  svg {
    width: 10px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const ChannelIcon = styled(Icon)`
  margin-right: 5px;

  svg {
    height: 20px;
    width: 20px;
  }

  ${up('md')} {
    margin-right: 0;

    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const ChannelLabel = styled.span`
  transition: 10s ease-in-out;
  display: none;
  line-height: 14px;
  margin: 0 10px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.smalt};

  ${up('md')} {
    display: block;
  }
`;

const getChannelItemVisualSpecs = (slug: ContactInteractionChannelSlug) =>
  match(slug)
    .with('FACEBOOK_MESSENGER', () => ({
      selectedIcon: 'facebookMessengerAlt',
      labelKey:
        'conversations:messageInput.tabs.reply.modals.channelSelection.options.facebookMessenger' as const,
      optionIcon: 'facebookMessenger',
      optionIconColor: 'customFacebook' as const,
    }))
    .with('SMS', () => ({
      selectedIcon: 'smsAlt',
      labelKey:
        'conversations:messageInput.tabs.reply.modals.channelSelection.options.sms' as const,
      optionIcon: 'sms',
      optionIconColor: 'topazDark' as const,
    }))
    .with('EMAIL', () => ({
      selectedIcon: 'mailAlt',
      labelKey:
        'conversations:messageInput.tabs.reply.modals.channelSelection.options.email' as const,
      optionIcon: 'mailAlt',
      optionIconColor: 'secondaryTopazDark' as const,
    }))
    .with('WHATSAPP', () => ({
      selectedIcon: 'whatsappFilled',
      labelKey:
        'conversations:messageInput.tabs.reply.modals.channelSelection.options.whatsapp' as const,
      optionIcon: 'whatsapp',
      optionIconColor: 'cosmo' as const,
    }))
    .with('INSTAGRAM', () => ({
      selectedIcon: 'instagramAlt',
      labelKey:
        'conversations:messageInput.tabs.reply.modals.channelSelection.options.instagram' as const,
      optionIcon: 'instagramAlt',
      optionIconColor: 'mediumRedViolet' as const,
    }))
    .otherwise(() => undefined);

const ReplyChannelIcon = styled(Icon)<{ $iconColor?: string }>`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme, $iconColor }) => theme.colors[$iconColor ?? 'flint']};
    }
  }
`;

type ReplyChannelOption = {
  value: ContactInteractionChannelSlug;
  label: string;
  icon: JSX.Element | null;
  'data-lgg-id': string;
  disabled: boolean;
  onClick: () => void;
};

type ReplyChannelsDrawerProps = {
  visible: boolean;
  onClose: VoidFunction;
  selectedChannel: ContactInteractionChannelSlug | null;
  setReplyChannel: ValueChanged<ContactInteractionChannelSlug>;
  replyChannels?: ContactInteractionChannelAvailabilityForContact[];
};

const ReplyChannelsDrawer = memo<ReplyChannelsDrawerProps>(
  ({ onClose, visible, selectedChannel, setReplyChannel, replyChannels = [] }) => {
    const { t } = useTranslation(['conversations']);
    const options = useMemo(
      () =>
        replyChannels
          .map((channel) => {
            const {
              isAvailable,
              channel: { slug },
            } = channel;
            const channelVisualSpec = getChannelItemVisualSpecs(slug);

            return {
              value: slug,
              label: channelVisualSpec ? t(channelVisualSpec.labelKey) : '',
              icon: channelVisualSpec ? (
                <ReplyChannelIcon
                  type={channelVisualSpec?.optionIcon}
                  $iconColor={channelVisualSpec?.optionIconColor}
                />
              ) : null,
              'data-lgg-id': `reply-channel-${slug}`,
              disabled: !isAvailable,
              onClick: () => {
                onClose();
                setReplyChannel(slug);
              },
            };
          })
          .filter(Boolean) as ReplyChannelOption[],
      [onClose, replyChannels, setReplyChannel, t],
    );

    return (
      <SelectableOptionsDrawer
        visible={visible}
        title={t('conversations:messageInput.tabs.reply.modals.channelSelection.title')}
        onClose={onClose}
        selectedValue={selectedChannel}
        options={options}
      />
    );
  },
);

type ReplyChannelSelectorProps = {
  selectedChannel: ContactInteractionChannelSlug | null;
  setSelectedChannel: ValueChanged<ContactInteractionChannelSlug>;
  replyChannelOptions: ContactInteractionChannelAvailabilityForContact[];
};

export const ReplyChannelSelector = ({
  selectedChannel,
  setSelectedChannel,
  replyChannelOptions,
}: ReplyChannelSelectorProps) => {
  const replyChannelSelectorVisibility = useVisible();
  const breakpointUpMd = useBreakpoint(up('md'));
  const { t } = useTranslation(['conversations']);

  const channelSelectionMenuItems = useMemo(
    () => (
      <ContextMenu data-lgg-id="reply-channel-selection-context-menu">
        <ContextMenuTitle>
          {`${t('conversations:messageInput.tabs.reply.modals.channelSelection.title')}:`}
        </ContextMenuTitle>
        {replyChannelOptions.map((channel) => {
          const {
            isAvailable,
            channel: { slug },
          } = channel;
          const channelVisualSpec = getChannelItemVisualSpecs(slug);

          if (!channelVisualSpec) {
            return null;
          }

          const { optionIcon, optionIconColor, labelKey } = channelVisualSpec;

          return (
            <ContextMenuItemContainer
              data-lgg-id={`reply-channel-${slug}`}
              className={isAvailable ? 'enabled' : 'disabled'}
              key={slug}
            >
              <ContextMenuItem
                onClick={() => {
                  if (isAvailable) setSelectedChannel(slug);

                  replyChannelSelectorVisibility.close();
                }}
              >
                <ContextMenuItemIcon $iconColor={optionIconColor} type={optionIcon} />
                <ContextMenuItemLabel isActive={selectedChannel === slug}>
                  {t(labelKey)}
                </ContextMenuItemLabel>
              </ContextMenuItem>
            </ContextMenuItemContainer>
          );
        })}
        <ContextMenuStyle />
      </ContextMenu>
    ),
    [
      replyChannelOptions,
      replyChannelSelectorVisibility,
      selectedChannel,
      setSelectedChannel,
      t,
    ],
  );

  const channelItemVisualSpecs = selectedChannel
    ? getChannelItemVisualSpecs(selectedChannel)
    : null;

  const channelSelectorDropdown = useMemo(
    () =>
      channelItemVisualSpecs ? (
        <DropdownContainer
          onClick={() => {
            replyChannelSelectorVisibility.setVisible(true);
          }}
          data-lgg-id="reply-channel-selection"
        >
          <ChannelIcon
            type={channelItemVisualSpecs.selectedIcon}
            lggTestId={`reply-channel-selection-icon-${selectedChannel}`}
          />
          <ChannelLabel>{t(channelItemVisualSpecs.labelKey)}</ChannelLabel>
          <DropdownIcon
            type="arrowdown"
            className={c({ active: replyChannelSelectorVisibility.visible })}
          />
        </DropdownContainer>
      ) : null,
    [channelItemVisualSpecs, replyChannelSelectorVisibility, selectedChannel, t],
  );

  if (!channelItemVisualSpecs) {
    return null;
  }

  return (
    <>
      {breakpointUpMd ? (
        <Dropdown
          overlayClassName="channel-selection-context-menu"
          overlay={channelSelectionMenuItems}
          onVisibleChange={replyChannelSelectorVisibility.setVisible}
          placement="topRight"
          trigger={['click']}
          align={{
            offset: [0, -10],
          }}
          disabled={!breakpointUpMd}
          visible={replyChannelSelectorVisibility.visible}
        >
          {channelSelectorDropdown}
        </Dropdown>
      ) : (
        <>
          {channelSelectorDropdown}
          <ReplyChannelsDrawer
            visible={!breakpointUpMd && replyChannelSelectorVisibility.visible}
            onClose={() => {
              replyChannelSelectorVisibility.setVisible(false);
            }}
            setReplyChannel={setSelectedChannel}
            selectedChannel={selectedChannel}
            replyChannels={replyChannelOptions}
          />
        </>
      )}
    </>
  );
};
