import { useCallback } from 'react';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

export const FILTER_DATE_FORMAT = 'YYYY-MM-DD+hh:mm+A';

export const START_FILTER_DATE_FORMAT = 'YYYY-MM-DD[+12:00+AM]';

export const END_FILTER_DATE_FORMAT = 'YYYY-MM-DD[+11:59+PM]';

export const useFilterHelpers = () => {
  const { parseDate } = useDateHelpers();
  const { formatDate } = useFormatDate();

  const encodeFilterDate = useCallback(
    (date: Date) => formatDate(date, FILTER_DATE_FORMAT, false),
    [formatDate],
  );

  const encodeStartFilterDate = useCallback(
    (date: Date) => formatDate(date, START_FILTER_DATE_FORMAT, false),
    [formatDate],
  );

  const encodeFilterEndDate = useCallback(
    (date: Date) => formatDate(date, END_FILTER_DATE_FORMAT, false),
    [formatDate],
  );

  const decodeFilterDate = useCallback(
    (encodedDate?: string) =>
      encodedDate
        ? parseDate(encodedDate?.replaceAll(' ', '+'), FILTER_DATE_FORMAT)
        : new Date(),
    [parseDate],
  );

  return {
    encodeFilterDate,
    decodeFilterDate,
    encodeFilterEndDate,
    encodeStartFilterDate,
  };
};
