import React, { memo } from 'react';
import { useServiceSelector } from 'react-service-locator';
import { animated, Spring } from 'react-spring';
import Layout from 'antd/lib/layout';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';
import { NotificationProvider } from 'src/components/general/feedback/hooks/use-show-notification';
import { Overlay } from 'src/components/general/overlay';
import { Header } from 'src/components/layout/authenticated-layout/header';
import { Sidebar } from 'src/components/layout/authenticated-layout/sidebar/sidebar';
import { FullPageLoading } from 'src/components/pages/company/company.page';
import { ApolloProviderProvider } from 'src/components/providers/apollo-provider-provider';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useSidebar } from 'src/hooks/use-sidebar';
import { GlobalLoadingService } from 'src/services/global-loading.service';

const ContentLayout = styled(Layout)`
  position: relative;

  ${down('sm')} {
    min-width: 100vw;
  }
`;

const Content = styled(Layout.Content)`
  max-width: 100%;
  overflow: auto;
  position: sticky;
`;

const StyledLayout = styled(Layout)`
  ${down('sm')} {
    overflow-x: hidden;
    width: 100vw;
  }
`;

const StyledOverlay = styled(Overlay)<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 300ms;
`;

export const AuthorizedLayout = memo(({ children }) => {
  const { isSidebarCollapsed, toggleSidebar } = useSidebar();
  const breakpointDownXl = useBreakpoint(down('xl'));
  const isLoading = useServiceSelector(GlobalLoadingService, (s) => s.state.isLoading);

  return (
    <ApolloProviderProvider>
      <NotificationProvider>
        <StyledLayout>
          <Sidebar />
          <ContentLayout>
            <Header />
            <Content>{children}</Content>
            <StyledOverlay
              visible={breakpointDownXl && !isSidebarCollapsed}
              onClick={toggleSidebar}
            />
          </ContentLayout>
        </StyledLayout>
        {isLoading && (
          <Spring from={{ opacity: 0 }} to={{ opacity: 0.7 }}>
            {(styles) => (
              <animated.div
                style={{
                  ...styles,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: ModalsZIndex,
                }}
              >
                <FullPageLoading />
              </animated.div>
            )}
          </Spring>
        )}
      </NotificationProvider>
    </ApolloProviderProvider>
  );
});
