import { match } from 'ts-pattern';
import {
  ContactInteractionParticipatingEntityAgent,
  ContactInteractionParticipatingEntityCallflow,
  ContactInteractionParticipatingEntityEdgeUnion,
} from '../types/__generated__/graphql';

export const participatingEntityPatterns = {
  agent: {
    __typename: 'ContactInteractionParticipatingEntityAgent',
    type: 'AGENT',
  } as const,
  callflow: {
    __typename: 'ContactInteractionParticipatingEntityCallflow',
    type: 'CALLFLOW',
  } as const,
};

type MatchParticipatingEntityHandler<Result> = {
  agent: (participatingEntity: ContactInteractionParticipatingEntityAgent) => Result;
  callflow: (attachment: ContactInteractionParticipatingEntityCallflow) => Result;
};

export const matchParticipatingEntityExhaustive = <Result>(
  participatingEntity: ContactInteractionParticipatingEntityEdgeUnion,
  handler: MatchParticipatingEntityHandler<Result>,
) => {
  return match(participatingEntity)
    .with(participatingEntityPatterns.agent, handler.agent)
    .with(participatingEntityPatterns.callflow, handler.callflow)
    .run();
};
