import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { ConversationItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionInputAreaTabKey } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-input-area';
import { $createMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';
import { useQueryParams } from 'src/hooks/use-query-params';

type ReplyDetectorPluginProps = {
  selectedInteraction?: ConversationItemUnion;
  setSelectedTabKey: ValueChanged<ContactInteractionInputAreaTabKey>;
  setHasFocus: ValueChanged<boolean>;
};

export const ReplyDetectorPlugin = ({
  selectedInteraction,
  setHasFocus,
  setSelectedTabKey,
}: ReplyDetectorPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const queryParams = useQueryParams();
  const replyDetected = queryParams['reply'] === 'true';
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (replyDetected) {
      history.push(location.pathname);

      if (selectedInteraction) {
        editor.update(() => {
          if (
            selectedInteraction &&
            selectedInteraction.__typename === 'ConversationNote'
          ) {
            const parentNode = $getRoot()
              .getFirstChild()
              ?.replace($createParagraphNode());
            const mentionNode = $createMentionNode(
              selectedInteraction.createdBy.fullName,
              selectedInteraction.createdBy.id.toString(),
            );

            parentNode?.append(mentionNode);
            parentNode?.append($createTextNode(' '));
            parentNode?.selectEnd();

            setSelectedTabKey(ContactInteractionInputAreaTabKey.InternalNote);
            setHasFocus(true);
            editor.focus();
          }
        });
      }
    }
  }, [
    editor,
    history,
    location.pathname,
    queryParams,
    replyDetected,
    selectedInteraction,
    setHasFocus,
    setSelectedTabKey,
  ]);

  return null;
};
