import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { omit, get, set, cloneDeep } from 'lodash';
import { useFilterHelpers } from 'src/components/general/filter/use-filter-helpers';
import { BasePageQueryParams, TableSortData } from 'src/components/general/table-helpers';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useQueryParams } from 'src/hooks/use-query-params';
import { stringifyQueryParams } from 'src/utils/stringify-query-params';

type UseLegacyQueryParamsResolverProps<
  PageAdvancedQueryParams,
  WhereInput,
  FiltersState,
  SortInput,
  ViewCode,
> = {
  rawQueryParams: Required<BasePageQueryParams<ViewCode, PageAdvancedQueryParams>>;
  pageSize: number;
  defaultSortData?: TableSortData;
  dateFilterKeys?: string[];
  defaultFilters: FiltersState;
  viewCodeResolver?: (
    params: BasePageQueryParams<ViewCode, PageAdvancedQueryParams>,
  ) => BasePageQueryParams<ViewCode, PageAdvancedQueryParams>;
  whereInputResolver: (params: BasePageQueryParams<ViewCode, PageAdvancedQueryParams>) =>
    | {
        _and: WhereInput[];
      }
    | {
        _or: WhereInput[];
      }
    | undefined;
  filtersStateResolver: (
    params: BasePageQueryParams<ViewCode, PageAdvancedQueryParams>,
  ) => FiltersState;
  sortInputResolver: (sortData: TableSortData) => SortInput;
};

type UseLegacyQueryParamsResolverResult<
  PageAdvancedQueryParams,
  WhereInput,
  FiltersState,
  SortInput,
  ViewCode,
> = {
  variables: {
    orderBy: SortInput | {};
    where: { _and: WhereInput[] } | { _or: WhereInput[] } | undefined;
    first: number | null;
  };
  queryParams: BasePageQueryParams<ViewCode, PageAdvancedQueryParams>;
  handlePreviousPage: (params: { startCursor: string }) => void;
  filters: FiltersState;
  hasActiveFilters: boolean;
  handleSortChange: (params: { columnKey?: string; sortDirection?: string }) => void;
  whereInput: { _and: WhereInput[] } | { _or: WhereInput[] } | undefined;
  sortData: TableSortData;
  handleNextPage: (params: { endCursor: string }) => void;
  legacyFiltersQueryString: string;
};

export const LEGACY_DATE_PARAM_FORMAT = 'YYYY-MM-DD hh:mm A';

export const LEGACY_DATE_PARAM_WO_Z = 'YYYY-MM-DD[T]hh:mm:ss.SSS';

const useBaseQueryVariables = <V, T>(options: {
  queryParams: BasePageQueryParams<V, T>;
  queryPageSize: number;
}) => {
  const { queryParams, queryPageSize } = options;

  if (queryParams.after) {
    return {
      after: queryParams.after,
      first: queryPageSize,
      last: null,
      before: null,
    };
  } else if (queryParams.before) {
    return {
      before: queryParams.before,
      after: null,
      first: null,
      last: queryPageSize,
    };
  } else {
    return {
      first: queryPageSize,
    };
  }
};

export const usePatchRawQueryParams = <PageAdvancedQueryParams, ViewCode>(props?: {
  defaultViewCode?: ViewCode;
}) => {
  const { defaultViewCode } = props ?? {};
  const rawQueryParams = useQueryParams() as BasePageQueryParams<
    ViewCode,
    PageAdvancedQueryParams
  >;

  if (!rawQueryParams['view-code'] && !rawQueryParams.q) {
    rawQueryParams['view-code'] = defaultViewCode ?? 'custom';
  }

  return rawQueryParams as Required<
    BasePageQueryParams<ViewCode, PageAdvancedQueryParams>
  >;
};

export const useLegacyParamsForDashboardQuery = <
  PageAdvancedQueryParams,
  WhereInput,
  FiltersState,
  SortInput,
  ViewCode,
>(
  props: UseLegacyQueryParamsResolverProps<
    PageAdvancedQueryParams,
    WhereInput,
    FiltersState,
    SortInput,
    ViewCode
  >,
): UseLegacyQueryParamsResolverResult<
  PageAdvancedQueryParams,
  WhereInput,
  FiltersState,
  SortInput,
  ViewCode
> => {
  const {
    viewCodeResolver,
    whereInputResolver,
    filtersStateResolver,
    sortInputResolver,
    pageSize,
    defaultSortData,
    dateFilterKeys,
    defaultFilters,
    rawQueryParams,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { encodeFilterDate } = useFilterHelpers();
  const { parseDate } = useDateHelpers();
  const viewCode = rawQueryParams['view-code'];
  const shouldAddViewCodeCustom = !viewCode && Boolean(rawQueryParams);

  if (shouldAddViewCodeCustom) {
    const newSearch = new URLSearchParams(location.search);
    newSearch.set('view-code', 'custom');
    history.replace(`${location.pathname}?${newSearch.toString()}`);
  }

  const queryParams = useMemo(
    () =>
      viewCode !== 'custom' && viewCodeResolver
        ? viewCodeResolver(rawQueryParams)
        : (rawQueryParams as BasePageQueryParams<ViewCode, PageAdvancedQueryParams>),
    [rawQueryParams, viewCode, viewCodeResolver],
  );

  const whereInput = useMemo(() => {
    const advanced = cloneDeep(queryParams?.q?.advanced || {});

    dateFilterKeys?.forEach((key) => {
      const target = get(advanced, key);

      if (target) {
        set(advanced, key, parseDate(target, LEGACY_DATE_PARAM_FORMAT).toISOString());
      }
    });

    if (advanced) {
      return whereInputResolver({
        ...queryParams,
        q: {
          ...queryParams.q,
          advanced: {
            ...(advanced as PageAdvancedQueryParams),
          },
        },
      });
    }

    return whereInputResolver(queryParams);
  }, [dateFilterKeys, parseDate, queryParams, whereInputResolver]);

  const filters = useMemo(() => {
    const filtersFromQueryParams = filtersStateResolver(queryParams);

    // @ts-expect-error mismatching types
    const filters = Object.keys(defaultFilters).reduce((acc, key) => {
      const valueKeys = Object.keys(defaultFilters[key] ?? {});

      if (valueKeys.length === 0) {
        acc[key] = filtersFromQueryParams[key] ?? defaultFilters[key];
      } else {
        valueKeys.forEach((innerKey) => {
          const queryValue = get(filtersFromQueryParams, [key, innerKey]);
          const value = queryValue ? queryValue : get(defaultFilters, [key, innerKey]);

          acc[key] = {
            ...acc[key],
            [innerKey]: value,
          };
        });
      }

      return acc;
    }, {});

    dateFilterKeys?.forEach((key) => {
      const targetValue = get(filters, key);

      if (targetValue) {
        set(
          filters,
          key,
          encodeFilterDate(parseDate(targetValue, LEGACY_DATE_PARAM_FORMAT)),
        );
      }
    });

    return filters;
  }, [
    dateFilterKeys,
    defaultFilters,
    encodeFilterDate,
    filtersStateResolver,
    parseDate,
    queryParams,
  ]);

  const baseVariables = useBaseQueryVariables<ViewCode, PageAdvancedQueryParams>({
    queryPageSize: pageSize,
    queryParams,
  });

  const resolveTableSortData = useCallback(() => {
    let sortData: TableSortData = defaultSortData ?? {
      key: '',
      direction: 'DESC',
    };
    const tableQueryParam = queryParams.table;

    if (tableQueryParam && typeof tableQueryParam === 'string') {
      const sortTokens = tableQueryParam.split('|');
      const key = sortTokens[1];
      const direction = sortTokens[3] as SortDirection;

      sortData = {
        key,
        direction,
      };
    }

    return sortData;
  }, [defaultSortData, queryParams.table]);

  const sortData = useMemo(() => resolveTableSortData(), [resolveTableSortData]);

  const handleSortChange = useCallback(
    (params: { columnKey?: string; sortDirection?: string }) => {
      const { columnKey, sortDirection } = params;
      const baseExcludeSortParams = ['before', 'after', 'table'];

      if (queryParams['view-code'] !== 'custom') {
        baseExcludeSortParams.push('q');
      }

      const cleanQueryParams = omit(queryParams, baseExcludeSortParams);

      if (columnKey && sortDirection) {
        const newUrl = stringifyQueryParams({
          ...cleanQueryParams,
          table: `sort|${columnKey}|direction|${sortDirection}`,
        });

        history.push(`${location.pathname}?${newUrl}`);
      } else {
        history.push(`${location.pathname}?${stringifyQueryParams(cleanQueryParams)}`);
      }
    },
    [history, location.pathname, queryParams],
  );

  const handleNextPage = useCallback(
    (params: { endCursor: string }) => {
      const { endCursor } = params;
      const baseExcludePaginationParams = ['before', 'after'];
      const cleanQueryParams = omit(queryParams, baseExcludePaginationParams);
      history.push(
        `${location.pathname}?${stringifyQueryParams({
          ...cleanQueryParams,
          after: endCursor,
        })}`,
      );
    },
    [history, location.pathname, queryParams],
  );

  const handlePreviousPage = useCallback(
    (params: { startCursor: string }) => {
      const { startCursor } = params;
      const baseExcludePaginationParams = ['before', 'after'];
      const cleanQueryParams = omit(queryParams, baseExcludePaginationParams);
      history.push(
        `${location.pathname}?${stringifyQueryParams({
          ...cleanQueryParams,
          before: startCursor,
        })}`,
      );
    },
    [history, location.pathname, queryParams],
  );

  const variables = useMemo(
    () => ({
      ...baseVariables,
      orderBy: sortData.key ? sortInputResolver(sortData) : {},
      where: whereInput,
    }),
    [baseVariables, sortData, sortInputResolver, whereInput],
  );

  const legacyFiltersQueryString = useMemo(() => {
    const reactExclusiveParams = ['before', 'after', 'view-code'];
    const cleanQueryParams = omit(queryParams, reactExclusiveParams);

    return stringifyQueryParams(cleanQueryParams);
  }, [queryParams]);

  return {
    queryParams,
    whereInput,
    variables,
    sortData,
    filters: filters as FiltersState,
    hasActiveFilters:
      filters && Object.values(filters).some((value) => value !== undefined),
    handleSortChange,
    handleNextPage,
    handlePreviousPage,
    legacyFiltersQueryString,
  };
};

export const viewCodeQueryParamKey = 'view-code';
