import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import { ContactInteractionInputAreaTabKey } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-input-area';

type TabChangePluginProps = {
  selectedTab: ContactInteractionInputAreaTabKey;
  onClear: (editor: LexicalEditor) => void;
};

export const TabChangePlugin = ({ onClear, selectedTab }: TabChangePluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => {
      onClear(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab],
  );

  return null;
};
