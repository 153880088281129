import React, { memo, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { primaryInput as devicePrimaryInput } from 'detect-it';
import styled from 'styled-components';

type ScrollbarProps = WithClassname & {
  children: React.ReactNode;
  containerRef?: (container: HTMLElement) => void;
  onClick?: VoidFunction;
};

const StyledNativeScrollbar = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
`;

export const Scrollbar = memo<ScrollbarProps>(({ children, ...props }) => {
  const Component = devicePrimaryInput === 'touch' ? NativeScrollbar : VirtualScrollbar;

  return (
    <Component className={`scrollbar ${props.className || ''}`} {...props}>
      {children}
    </Component>
  );
});

export const NativeScrollbar = memo<ScrollbarProps>(
  ({ children, containerRef, ...props }) => {
    const scrollbarDOMRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (containerRef) {
        containerRef(scrollbarDOMRef.current as HTMLElement);
      }
    }, [containerRef]);

    return (
      <StyledNativeScrollbar {...props} ref={scrollbarDOMRef}>
        {children}
      </StyledNativeScrollbar>
    );
  },
);

export const VirtualScrollbar = memo<ScrollbarProps>(({ children, ...props }) => {
  const scrollbarRef = useRef<PerfectScrollbar | null>(null);

  useEffect(() => {
    if (scrollbarRef.current) {
      window.requestAnimationFrame(() => {
        scrollbarRef.current?.updateScroll();
      });
    }
  });

  return (
    <PerfectScrollbar {...props} ref={(ref) => (scrollbarRef.current = ref)}>
      {children}
    </PerfectScrollbar>
  );
});
