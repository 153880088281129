import React, { memo, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import * as zod from 'zod';
import {
  ContactInteractionDirection,
  Query,
  QueryContactInteractionChannelsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCampaignListForSelect } from 'src/components/domain/campaign/hooks/use-campaign-list-for-select';
import { useContactSourceListForSelect } from 'src/components/domain/contacts/hooks/use-contact-source-list-for-select';
import { useActiveDepartmentSelectOptions } from 'src/components/domain/departments/hooks/use-active-department-select-options';
import { useUserSelectOptions } from 'src/components/domain/users/hooks/use-user-select-options';
import { useSetFormFieldValue } from 'src/components/filters/helpers';
import {
  EndDateFilter,
  ReactFilters,
  StartDateFilter,
} from 'src/components/filters/react-filters';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import {
  StyledTableFiltersForm,
  TableLayoutFilterProps,
  useTableSubmitHandler,
} from 'src/components/general/table-helpers';
import { ContactTagSelect } from 'src/components/pages/conversations/components/contact-information/legacy-contact-tags-selector';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const schema = zod.object({
  date_from: zod.string().nullish(),
  date_to: zod.string().nullish(),
  contact: zod.string().optional(),
  medium_status: zod.string().nullish(),
  department: zod.union([zod.number(), zod.literal('NULL')]).nullish(),
  assigned_unassigned: zod.number().nullish(),
  Owner: zod.number().nullish(),
  answered_by: zod.number().nullish(),
  campaign: zod.number().nullish(),
  channel: zod.number().nullish(),
  source: zod.number().nullish(),
  medium: zod.number().nullish(),
  direction: zod.union([zod.literal('INBOUND'), zod.literal('OUTBOUND')]).nullish(),
  tag: zod.number().nullish(),
});

export const CONTACT_INTERACTION_CHANNELS = gql`
  query GetContactInteractionChannels($institutionId: Int!) {
    contactInteractionChannels(institutionId: $institutionId) {
      id
      slug
      name
    }
  }
`;

export type ActivityFiltersFormValues = zod.infer<typeof schema>;

export const defaultActivityFilters: ActivityFiltersFormValues = {
  date_from: null,
  date_to: null,
  contact: '',
  medium_status: null,
  department: null,
  assigned_unassigned: null,
  Owner: null,
  answered_by: null,
  campaign: null,
  channel: null,
  source: null,
  medium: null,
  direction: null,
  tag: null,
};

export const ActivityFilters = memo<TableLayoutFilterProps<ActivityFiltersFormValues>>(
  ({ visible, filters, onClose }) => {
    const { t } = useTranslation(['common', 'activity']);
    const history = useHistory();
    const location = useLocation();
    const { campaignOptions, loadingCampaignOptions } = useCampaignListForSelect();
    const { sourceOptions, loadingSourceOptions } = useContactSourceListForSelect();
    const { loading: loadingUserOptions, userOptions } = useUserSelectOptions({
      isActive: { _eq: true },
    });
    const { loadingDepartmentOptions, departmentOptions } =
      useActiveDepartmentSelectOptions();
    const { id: currentInstitutionId } = useCurrentInstitution();
    const handleGraphQLError = useHandleGraphQLError();

    const form = useForm<ActivityFiltersFormValues>({
      resolver: zodResolver(schema),
      defaultValues: filters,
    });

    const channelsQuery = useQuery<
      Pick<Query, 'contactInteractionChannels'>,
      QueryContactInteractionChannelsArgs
    >(CONTACT_INTERACTION_CHANNELS, {
      variables: {
        institutionId: currentInstitutionId,
      },
      onError: handleGraphQLError,
    });

    const mediumOptions = useMemo(
      () =>
        channelsQuery.data?.contactInteractionChannels.map((channel) => ({
          value: channel.id,
          label: channel.name,
        })) ?? [],
      [channelsQuery.data?.contactInteractionChannels],
    );

    const { control, handleSubmit, reset, setValue } = form;

    const { setFormFieldValue } = useSetFormFieldValue<ActivityFiltersFormValues>({
      defaultValues: defaultActivityFilters,
      setValue,
    });

    useEffect(() => {
      if (filters) {
        reset(filters);
      }
    }, [filters, reset]);

    const submitHandler = useTableSubmitHandler({ onClose });
    const callStatusOptions: SelectOption<string>[] = [
      {
        label: t('activity:filters.callStatus.options.waitingHangUp'),
        value: 'CALL:INBOUND:INIT',
      },
      {
        label: t('activity:filters.callStatus.options.unanswered'),
        value: 'CALL:INBOUND:UNANSWERED',
      },
      {
        label: t('activity:filters.callStatus.options.answered'),
        value: 'CALL:INBOUND:ANSWERED',
      },
    ];

    const departmentSelectOptions: SelectOption<number | 'NULL'>[] = [
      {
        label: t('activity:filters.department.options.noDepartmentRelated'),
        value: 'NULL',
      },
      ...departmentOptions,
    ];

    const assignedUnassignedOptions: SelectOption<number>[] = [
      {
        label: t('activity:filters.assignedUnassigned.options.Assigned'),
        value: 1,
      },
      {
        label: t('activity:filters.assignedUnassigned.options.Unassigned'),
        value: 0,
      },
    ];

    const directionOptions: SelectOption<ContactInteractionDirection>[] = [
      {
        label: t('activity:filters.direction.options.incoming'),
        value: 'INBOUND',
      },
      {
        label: t('activity:filters.direction.options.outgoing'),
        value: 'OUTBOUND',
      },
    ];

    return (
      <ReactFilters
        form={form}
        onSave={() => {
          void handleSubmit(submitHandler)();
        }}
        onReset={() => {
          onClose();
          reset(defaultActivityFilters);
          history.push(location.pathname);
        }}
        filters={filters}
        visible={visible}
        onClose={onClose}
        testId="activity-filters"
      >
        <StyledTableFiltersForm>
          <StartDateFilter
            name="date_from"
            data-lgg-id="activity-filters-field-date-from"
            label={t('activity:filters.dateFrom')}
          />
          <EndDateFilter
            name="date_to"
            data-lgg-id="activity-filters-field-date-to"
            label={t('activity:filters.dateTo')}
          />
          <Controller
            control={control}
            name="contact"
            render={({ field }) => (
              <TextInput
                data-lgg-id="activity-filters-field-contact"
                autoCapitalize="off"
                label={t('activity:filters.contact')}
                reserveErrorArea={false}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="medium_status"
            render={({ field }) => {
              const selectedOption =
                callStatusOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-call-status"
                  value={selectedOption}
                  options={callStatusOptions}
                  isSearchable={false}
                  label={t('activity:filters.callStatus.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="department"
            render={({ field }) => {
              const selectedOption =
                departmentSelectOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select<SelectOption<number | 'NULL'>>
                  name="activity-filters-field-department"
                  value={selectedOption}
                  options={departmentSelectOptions}
                  isLoading={loadingDepartmentOptions}
                  isSearchable={false}
                  label={t('activity:filters.department.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="assigned_unassigned"
            render={({ field }) => {
              const selectedOption =
                assignedUnassignedOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-assigned-unassigned"
                  value={selectedOption}
                  options={assignedUnassignedOptions}
                  isSearchable={false}
                  label={t('activity:filters.assignedUnassigned.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="Owner"
            render={({ field }) => {
              const selectedOption =
                userOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-owner"
                  value={selectedOption}
                  options={userOptions}
                  isSearchable={true}
                  isLoading={loadingUserOptions}
                  label={t('activity:filters.owner')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="answered_by"
            render={({ field }) => {
              const selectedOption =
                userOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-answered-by"
                  value={selectedOption}
                  options={userOptions}
                  isLoading={loadingUserOptions}
                  isSearchable={true}
                  label={t('activity:filters.answeredBy')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="campaign"
            defaultValue={null}
            render={({ field }) => {
              const selectedOption =
                campaignOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-campaign"
                  value={selectedOption}
                  options={campaignOptions}
                  isLoading={loadingCampaignOptions}
                  isSearchable={true}
                  label={t('activity:filters.campaign')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="source"
            render={({ field }) => {
              const selectedOption =
                sourceOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-source"
                  value={selectedOption}
                  options={sourceOptions}
                  isSearchable={true}
                  isLoading={loadingSourceOptions}
                  label={t('activity:filters.source')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="medium"
            render={({ field }) => {
              const selectedOption =
                mediumOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-medium"
                  value={selectedOption}
                  options={mediumOptions}
                  isSearchable={true}
                  label={t('activity:filters.medium.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="direction"
            render={({ field }) => {
              const selectedOption =
                directionOptions.filter(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  name="activity-filters-field-direction"
                  value={selectedOption}
                  options={directionOptions}
                  isSearchable={true}
                  label={t('activity:filters.direction.title')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="tag"
            render={({ field }) => {
              return (
                <ContactTagSelect
                  name="activity-filters-field-tag"
                  label={t('activity:filters.tag')}
                  isMulti={false}
                  selectedTagsIds={field.value ? [field.value] : []}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
        </StyledTableFiltersForm>
      </ReactFilters>
    );
  },
);
