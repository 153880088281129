import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useServiceSelector } from 'react-service-locator';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { SessionService } from 'src/services/session.service';
import { useUrls } from '../use-urls';

type PushNotificationMetadata =
  | {
      type: 'contact.assigned';
      contactId: number;
    }
  | {
      type: 'contact.status-changed';
      contactId: number;
    }
  | {
      type: 'contact.unassigned';
      contactId: number;
    }
  | {
      type: 'contact.updated';
      contactId: number;
    }
  | {
      type: 'schedule.created';
      appointmentId: number;
    }
  | {
      type: 'schedule.assigned';
      appointmentId: number;
    }
  | {
      type: 'schedule.updated';
      appointmentId: number;
    }
  | {
      type: 'schedule.canceled';
      appointmentId: number;
    }
  | {
      type: 'task.assigned';
      taskId: number;
    }
  | {
      type: 'task.reminder';
      taskId: number;
    }
  | {
      type: 'task.completed';
      taskId: number;
    }
  | {
      type: 'task.updated';
      taskId: number;
    }
  | {
      type: 'task.overdue';
      taskId: number;
    }
  | {
      type: 'call.missed';
      contactInteractionId: number;
    }
  | {
      type: 'contact-interaction.new';
      contactInteractionId: number;
    }
  | {
      type: 'conversation-note.new';
      conversationNoteId: string;
      conversationId: string;
    }
  | {
      type: 'conversation-note.mention';
      conversationNoteId: string;
      conversationId: string;
    }
  | {
      type: 'report.ready';
      reportUrl: string;
      jobId: number;
    }
  | {
      type: 'message-delivery-failure.payment';
      contactInteractionId: number;
      waba: string;
    };

export const useOpenNotification = () => {
  const history = useHistory();
  const userId = useServiceSelector(SessionService, ({ data: { user } }) => {
    return user.id;
  });
  const {
    getContactModalUrl,
    getScheduleModalUrl,
    getTaskModalUrl,
    getContactInteractionUrl,
    getInternalNoteUrl,
  } = useUrls();

  useEffect(() => {
    if (!Capacitor.isNativePlatform() || !userId) {
      return;
    }

    OneSignal.setNotificationOpenedHandler(({ notification }) => {
      const metadata = notification.additionalData as PushNotificationMetadata;

      if (metadata.type) {
        switch (metadata.type) {
          case 'contact.assigned':
          case 'contact.status-changed':
          case 'contact.unassigned':
          case 'contact.updated': {
            history.push(getContactModalUrl(metadata.contactId));
            break;
          }
          case 'schedule.created':
          case 'schedule.assigned':
          case 'schedule.updated':
          case 'schedule.canceled': {
            history.push(getScheduleModalUrl(metadata.appointmentId));
            break;
          }
          case 'task.assigned':
          case 'task.reminder':
          case 'task.completed':
          case 'task.overdue':
          case 'task.updated': {
            history.push(getTaskModalUrl(metadata.taskId));
            break;
          }
          case 'call.missed':
          case 'contact-interaction.new':
          case 'message-delivery-failure.payment': {
            history.push(getContactInteractionUrl(metadata.contactInteractionId));
            break;
          }
          case 'conversation-note.new':
          case 'conversation-note.mention': {
            history.push(
              getInternalNoteUrl({
                conversationId: metadata.conversationId,
                noteId: metadata.conversationNoteId,
              }),
            );
            break;
          }
          case 'report.ready': {
            history.push(metadata.reportUrl);
            break;
          }
          default: {
            return;
          }
        }
      }
    });
  }, [
    getContactInteractionUrl,
    getContactModalUrl,
    getInternalNoteUrl,
    getScheduleModalUrl,
    getTaskModalUrl,
    history,
    userId,
  ]);
};
