import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContactInteractionUnknown } from '@lgg/isomorphic/types/__generated__/graphql';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  BubbleDivider,
  BubbleMessageHeader,
  InteractionTextContent,
  MessageBubbleWithDirection,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';

const StyledBubbleMessageHeader = styled(BubbleMessageHeader)`
  .interaction-icon svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

type ContactInteractionUnknownConversationItemProps = {
  contactInteraction: ContactInteractionUnknown;
  conversationId: string;
  contactInteractionLogId?: string;
};

export const ContactInteractionUnknownConversationItem =
  memo<ContactInteractionUnknownConversationItemProps>(
    ({ contactInteraction, conversationId, contactInteractionLogId }) => {
      const { t } = useTranslation(['conversations']);
      const {
        isCreationAutomatic,
        id,
        description,
        occurredAt,
        source,
        campaign,
        direction,
        department,
      } = contactInteraction;

      return (
        <MessageBubbleWithDirection
          testId="contact-interaction-unknown"
          isCreationAutomatic={isCreationAutomatic}
          direction={direction}
          contactInteractionType={contactInteraction.__typename}
          contactInteractionLogId={contactInteractionLogId}
          details={{
            interactionId: id,
            conversationId,
            source,
            campaign,
            department,
            message: description,
          }}
          createdAt={occurredAt}
        >
          <>
            <StyledBubbleMessageHeader
              contactInteraction={contactInteraction}
              icon="interaction"
              title={t('conversations:contactInteractionBubble.unknownInteraction')}
            />
            <BubbleDivider direction={direction} />
            {description && (
              <InteractionTextContent direction={direction}>
                <TextFormatter>{description}</TextFormatter>
              </InteractionTextContent>
            )}
          </>
        </MessageBubbleWithDirection>
      );
    },
  );
