import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  QueryMatch,
  TypeaheadOption,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $getSelection, TextNode } from 'lexical';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactModalZIndex } from 'src/components/constants';
import { useActiveUsersGroupedByRole } from 'src/components/domain/users/hooks/use-active-users-grouped-by-role';
import { useUsersListForSelect } from 'src/components/domain/users/hooks/use-users-list-for-select';
import { Avatar } from 'src/components/general/display/avatar';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { $createMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const CapitalizedNameMentionsRegex = new RegExp(
  '(^|[^#])((?:' + DocumentMentionsRegex.NAME + '{' + 1 + ',})$)',
);

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$',
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$',
);

const checkForCapitalizedNameMentions = (
  text: string,
  minMatchLength: number,
): QueryMatch | null => {
  const match = CapitalizedNameMentionsRegex.exec(text);

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[2];

    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }

  return null;
};

const checkForAtSignMentions = (
  text: string,
  minMatchLength: number,
): QueryMatch | null => {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];

    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }

  return null;
};

const getPossibleQueryMatch = (text: string): QueryMatch | null => {
  const match = checkForAtSignMentions(text, 0);

  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
};

const StyledAvatar = styled(Avatar)<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-size: 9px;
  letter-spacing: -0.27px;
  color: rgba(75, 104, 130, 0.3);

  ${up('md')} {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }
`;

const UserList = styled.div`
  margin: 0 10px;
  width: calc(100% - 20px);
  bottom: calc(100% - 9px);
  max-height: 186px;
  padding: 10px 12px;
  position: absolute;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.koala};
  border-radius: 6px;
  box-shadow: 0 20px 40px 0 rgba(91, 101, 112, 0.1);
  z-index: ${ContactModalZIndex};

  ${up('md')} {
    width: 224px;
    margin: 0;
    bottom: 28px;
    padding: 5px 11px 5px 5px;
    max-height: 147px;
  }
`;

const UserListItem = styled(FlexRow)`
  padding: 5px 8px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;

  ${up('md')} {
    padding: 7px 6px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const UserListItemLabel = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
  letter-spacing: -0.14px;
  font-size: 14px;
  line-height: 16px;

  ${up('md')} {
    letter-spacing: -0.12px;
    font-size: 12px;
    line-height: 14px;
  }
`;

const MentionIcon = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;

const GroupHeading = styled.p`
  border-bottom: solid 1px ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.12px;
  line-height: 14px;
  margin-bottom: 3px;
  overflow: hidden;
  padding: 7px 10px;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: unset;
  white-space: nowrap;
`;

class MentionTypeaheadOption extends TypeaheadOption {
  name: string;
  userId: string;
  avatar: User['avatar'];

  public constructor(name: string, userId: string, avatar: User['avatar']) {
    super(userId);

    this.name = name;
    this.userId = userId;
    this.avatar = avatar;
  }
}

type MentionsPluginProps = {
  editorContainerRef: React.RefObject<HTMLDivElement>;
  editorRef: React.RefObject<HTMLDivElement>;
};

export const MentionsPlugin = ({
  editorContainerRef,
  editorRef,
}: MentionsPluginProps): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);
  const breakpointUpMd = useBreakpoint(up('md'));
  const mentionModalRef = React.useRef<HTMLDivElement>(null);
  const { users } = useUsersListForSelect({ isActive: { _eq: true } });
  const { usersGroups } = useActiveUsersGroupedByRole();

  const mentionPopoverVisibilityHandler = useVisible(false);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        mentionPopoverVisibilityHandler.visible &&
        mentionModalRef.current &&
        !mentionModalRef.current.contains(event.target) &&
        !editorRef.current?.contains(event.target)
      ) {
        mentionPopoverVisibilityHandler.close();
      }
    },
    [editorRef, mentionPopoverVisibilityHandler],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const filteredOptions = useMemo(() => {
    return queryString
      ? users.filter((user) =>
          user.fullName.toLowerCase().includes(queryString.toLocaleLowerCase()),
        )
      : users;
  }, [queryString, users]);

  const userOptions: MentionTypeaheadOption[] = filteredOptions.map((val) => {
    return new MentionTypeaheadOption(val.fullName, val.id.toString(), val.avatar);
  });

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void,
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.name, selectedOption.key);

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        mentionNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const mentionMatch = getPossibleQueryMatch(text);

      mentionPopoverVisibilityHandler.setVisible(mentionMatch !== null);
      return mentionMatch;
    },
    [mentionPopoverVisibilityHandler],
  );

  return (
    <>
      <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
        onQueryChange={setQueryString}
        onSelectOption={onSelectOption}
        triggerFn={checkForMentionMatch}
        options={userOptions}
        menuRenderFn={(anchorElementRef, { selectOptionAndCleanUp, options }) => {
          const currentMentionBoundingInfo =
            anchorElementRef.current?.getBoundingClientRect();
          const mentionModalBoundingInfo =
            mentionModalRef.current?.getBoundingClientRect();
          let outPixels = 0;

          const matchingUserIds = options?.map((option) => option.userId) ?? [];

          const groupList =
            usersGroups
              ?.map((group) => {
                const groupMatchingOptions = group.users.filter((user) =>
                  matchingUserIds.includes(user.id.toString()),
                );

                return {
                  label: group.label,
                  options: groupMatchingOptions.map(({ fullName, id, avatar }) => {
                    return new MentionTypeaheadOption(fullName, id.toString(), avatar);
                  }),
                };
              })
              .filter(({ options }) => options?.length > 0) ?? [];

          if (breakpointUpMd && currentMentionBoundingInfo && mentionModalBoundingInfo) {
            outPixels =
              window.innerWidth -
              (currentMentionBoundingInfo?.left + mentionModalBoundingInfo.width);
          }

          return mentionPopoverVisibilityHandler.visible &&
            anchorElementRef.current &&
            editorContainerRef.current &&
            options.length
            ? ReactDOM.createPortal(
                <UserList
                  data-lgg-id="user-mention-options-container"
                  ref={mentionModalRef}
                  style={{
                    left: outPixels >= 0 ? 'unset' : `${outPixels - 5}px`,
                  }}
                >
                  {groupList.map(({ label, options }) => {
                    return (
                      <div key={`user-mention-group-${label}`}>
                        <GroupHeading
                          data-lgg-id="user-mention-group-title"
                          title={label}
                        >
                          {label}
                        </GroupHeading>
                        <span>
                          {options.map((option) => (
                            <UserListItem
                              data-lgg-id={`user-mention-option-${option.userId}`}
                              key={option.key}
                              ref={option.setRefElement}
                              onClick={() => {
                                selectOptionAndCleanUp(option);

                                editor.update(() => {
                                  const selection = $getSelection();

                                  if (selection) {
                                    selection.insertText(' ');
                                  }
                                });
                              }}
                            >
                              <StyledAvatar color={option.avatar.color}>
                                {option.avatar.initials}
                              </StyledAvatar>
                              <UserListItemLabel>{option.name}</UserListItemLabel>
                            </UserListItem>
                          ))}
                        </span>
                      </div>
                    );
                  })}
                </UserList>,
                breakpointUpMd ? anchorElementRef.current : editorContainerRef.current,
              )
            : null;
        }}
      />
      <MentionIcon
        type="mention"
        lggTestId="input-area-mention-picker-trigger"
        onClick={() => {
          editor.update(() => {
            const selection = $getSelection();

            if (selection) {
              selection.insertText('@');
            }
          });
        }}
      />
    </>
  );
};
