import { useCallback, useMemo } from 'react';
import { match, P } from 'ts-pattern';
import { ContactModalTabKey } from 'src/components/domain/contacts/contact-modal/contact-modal.shared';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';

type ID = string | number;
type UseUrlOptions = { includeHost: boolean };

export const useUrls = () => {
  const institutionUrl = useInstitutionUrl();
  const hostResolver = useCallback((url: string, options?: UseUrlOptions) => {
    if (options?.includeHost) {
      return `${window.location.origin}${url}`;
    } else {
      return url;
    }
  }, []);

  return useMemo(
    () => ({
      getFacebookSettingsPageUrl: () => `${institutionUrl}settings/facebook/`,
      getLegacyContactEditUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/edit/lead_id:${contactId}/`,
      getLegacyContactCallUrl: (contactId: ID) =>
        `${institutionUrl}_modals/call?id=${contactId}/`,
      getContactCallUrl: (contactId: ID) =>
        `${institutionUrl}modal/call/contact/${contactId}/`,
      getLegacyContactAddInteractionUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/items/activity/edit/lead_id:${contactId}/`,
      getLegacyContactAddTaskUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/task/edit/lead_id:${contactId}/`,
      getLegacyAddTaskUrlWithDate: (targetDate?: string) =>
        `${institutionUrl}cms/_modals/task/edit/${
          targetDate ? `?date=${targetDate}` : ''
        }`,
      getLegacyTaskEditUrl: (taskId: ID) =>
        `${institutionUrl}cms/_modals/task/edit/id:${taskId}/`,
      getContactModalUrl: (
        contactId: number,
        tab?:
          | ContactModalTabKey
          | {
              conversationId?: string;
              contactInteractionId?: string;
            },
      ) => {
        const baseUrl = `${institutionUrl}modal/contact/${contactId}`;
        return match(tab)
          .with('tasks', () => `${baseUrl}/tasks`)
          .with('appointments', () => `${baseUrl}/appointments`)
          .with('notes', () => `${baseUrl}/notes`)
          .with('contact-details', () => `${baseUrl}/contact-details`)
          .with(
            P.union(
              'conversations',
              {
                conversationId: P.string,
              },
              {
                conversationId: P.string,
                contactInteractionId: P.number,
              },
            ),
            (value) => {
              const baseConversationUrl = `${baseUrl}/conversations`;
              if (value === 'conversations') {
                return baseConversationUrl;
              }

              const { conversationId, contactInteractionId } = value;

              if (value.contactInteractionId) {
                return `${baseUrl}/conversations/${conversationId}/${contactInteractionId}`;
              }

              return `${baseUrl}/conversations/${conversationId}`;
            },
          )
          .otherwise(() => baseUrl);
      },
      getTaskModalUrl: (taskId: ID) => `${institutionUrl}modal/task/${taskId}`,
      getTaskModalEditForContactUrl: (contactId: ID) =>
        `${institutionUrl}modal/task/edit/contact/${contactId}`,

      getLegacyScheduleEditUrl: (scheduleId: ID, contactId?: ID) =>
        `${institutionUrl}cms/_modals/schedule/edit/id:${scheduleId}/${
          contactId ? `lead_id:${contactId}` : ''
        }`,
      getScheduleModalUrl: (scheduleId: ID, contactId?: ID) => {
        const url = `${institutionUrl}modal/schedule/edit/${scheduleId}`;

        if (contactId) {
          return `${url}/contact/${contactId}`;
        }

        return url;
      },
      getScheduleModalEditForContactUrl: (contactId?: ID) =>
        `${institutionUrl}modal/schedule/edit/contact/${contactId}`,
      getLegacyContactAddScheduleUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/schedule/edit/lead_id:${contactId}/`,
      getLegacyAddScheduleUrlWithDate: (targetDate?: string) =>
        `${institutionUrl}cms/_modals/schedule/edit/${
          targetDate ? `?date=${targetDate}` : ''
        }`,
      getLegacyContactAddNoteUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/items/note/edit/lead_id:${contactId}/`,
      getLegacyContactEditNoteUrl: (contactId: ID, noteId: ID) =>
        `${institutionUrl}cms/_modals/client/items/note/edit/id:${noteId}/lead_id:${contactId}`,
      getLegacyContactFollowUpUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/task/followup/lead_id:${contactId}/`,
      getLegacyContactDetailUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/lead_id:${contactId}/`,
      getLegacyContactImportUrl: () => `${institutionUrl}contacts/import/`,
      getLegacyContactStatusModalUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/status/lead_id:${contactId}/`,
      getLegacyContactInterestModalUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/interest/lead_id:${contactId}/`,
      getLegacyContactAssigneeModalUrl: (contactId: ID) =>
        `${institutionUrl}cms/_modals/client/owner/lead_id:${contactId}/`,
      getLegacyDebugCallUrl: (activityId: ID) =>
        `${institutionUrl}activity/_system/debug_call/a_id:${activityId}/`,
      getLegacyDebugNotificationUrl: (activityId: ID) =>
        `${institutionUrl}activity/_system/check_notifications/a_id:${activityId}/`,
      getLegacyContactConversationModalUrl: (contactId: ID) =>
        `${institutionUrl}_modals/lead/conversation/id:${contactId}`,
      getLegacyContactInteractionModalUrl: (contactInteractionId: string) =>
        `${institutionUrl}_modals/lead/history/id:${contactInteractionId}/`,
      getLegacyContactInteractionDepartmentModalUrl: (activityId: string) =>
        `${institutionUrl}_modals/lead/history/department/a_id:${activityId}/`,
      getConversationUrl: (conversationId: string) =>
        `${institutionUrl}conversations/${conversationId}`,
      getConversationContactInteractionUrl: (params: {
        conversationId: string;
        contactInteractionId: ID;
        options?: UseUrlOptions;
      }) => {
        const { conversationId, contactInteractionId, options } = params;
        const url = `${institutionUrl}conversations/${conversationId}/${contactInteractionId}`;

        return hostResolver(url, options);
      },
      getContactInteractionUrl: (contactInteractionId: ID, options?: UseUrlOptions) =>
        hostResolver(
          `${institutionUrl}contact/interaction/${contactInteractionId}/`,
          options,
        ),
      getInternalNoteUrl: (params: {
        conversationId: string;
        noteId: string;
        options?: UseUrlOptions;
        isReplying?: boolean;
      }) => {
        const { conversationId, noteId, options, isReplying = false } = params;

        return hostResolver(
          `${institutionUrl}conversations/${conversationId}/note-${noteId}${
            isReplying ? '?reply=true' : ''
          }`,
          options,
        );
      },
      getLegacyEditUserUrl: () => `${institutionUrl}_actions/account/view/edit`,
      getWhatsappResourceUrl: (contactId: ID) =>
        `${institutionUrl}/whatsapp-channel/${contactId}`,
    }),
    [hostResolver, institutionUrl],
  );
};
