import React, {
  ComponentProps,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import c from 'classnames';
import { LexicalEditor as LexicalEditorType } from 'lexical';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  ContactInteractionChannelSlug,
  ConversationItemUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  ContactInteractionsInputAreaAttachmentOptions,
  InputUploadFile,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-area-attachment-options';
import { ContactInteractionInputAreaTabKey } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-input-area';
import { MessageAttachments } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-message-attachments';
import { DesktopSendKeyboardShortcutPlugin } from 'src/components/pages/conversations/components/general/lexical-editor//plugins/desktop-send-keyboard-shortcut-plugin';
import { UserMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';
import { AiToolsPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/ai-tools-picker-plugin';
import { TabChangePlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/custom-clear-editor-plugin';
import { PasteDnDFilePlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/dnd-paste-plugin';
import { EmojiPickerPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/emoji-picker-plugin';
import { MentionsPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/mentions-plugin';
import { ReplyDetectorPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/reply-detector-plugin';
import { LexicalContainer } from 'src/components/pages/conversations/components/general/lexical-editor/shared';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const LeftRail = styled(FlexRow)`
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const RightRail = styled(FlexRow)`
  max-height: 16px;
`;

const BottomBarContainer = styled(FlexRow)`
  align-items: center;
  display: none;
  justify-content: space-between;
  padding: 0 20px 7px;

  ${up('md')} {
    padding: 0 20px 12px;
  }

  &.visible {
    display: flex;
  }

  &.unfocused {
    bottom: 15px;
    height: 20px;
    min-height: 20px;
    padding: 0;
    position: absolute;
    right: 20px;

    ${LeftRail} {
      display: none;
    }
  }
`;

const SendIcon = styled(Icon)<{ $isActive: boolean }>`
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'not-allowed')};
  height: 20px;
  width: 20px;

  svg path {
    fill: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.monk : theme.colors.casper};
  }
`;

const LexicalEditorContainer = styled(FlexColumn)`
  flex: 1;
`;

type SendIconButtonProps = {
  isInputActive: boolean;
  onClick: (editor: LexicalEditorType) => void;
};

const SendIconButton = memo<SendIconButtonProps>(({ isInputActive, onClick }) => {
  const [editor] = useLexicalComposerContext();

  return (
    <SendIcon
      type="send"
      lggTestId="contact-interaction-message-submit-button"
      $isActive={isInputActive}
      onClick={() => {
        onClick(editor);
      }}
    />
  );
});

type LexicalEditorProps = {
  placeholder: string;
  selectedChannel: ContactInteractionChannelSlug | null;
  onFocus?: VoidFunction;
  onChange: ComponentProps<typeof OnChangePlugin>['onChange'];
  hasFocus: boolean;
  isFullHeight: boolean;
  handleSendMessage: (editor: LexicalEditorType) => void;
  pluginConfig: {
    emojiPicker: boolean;
    messageAttachments: boolean;
    mentions: boolean;
    aiTools: boolean;
  };
  isInputActive: boolean;
  bottomContent: {
    leading?: ReactNode;
    trailing?: ReactNode;
    visible: boolean;
  };
  topContent?: ReactNode;
  attachmentList: InputUploadFile[];
  setAttachmentList: Dispatch<SetStateAction<InputUploadFile[]>>;
  onClear: (editor: LexicalEditorType) => void;
  selectedTab: ContactInteractionInputAreaTabKey;
  setSelectedTabKey: ValueChanged<ContactInteractionInputAreaTabKey>;
  setHasFocus: ValueChanged<boolean>;
  selectedInteraction?: ConversationItemUnion;
  isInputEnabled: boolean;
  rightRailLeadingContent?: ReactNode;
};

const TextInputContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 17px;
  max-height: 155px;
  height: max-content;

  .editor-input {
    max-height: 140px;
  }

  &.full-height {
    max-height: unset;
    flex: 1;

    .editor-input {
      flex: 1;
    }
  }

  ${up('md')} {
    padding-top: 19px;
    padding-bottom: 18px;
    max-height: unset;

    &.focused {
      padding-top: 15px;
    }
  }
`;

const lexicalEditorConfig = {
  namespace: 'LggEditor',
  theme: {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
  },
  onError(error) {
    throw error;
  },
  nodes: [UserMentionNode],
};

export const LexicalEditor = memo<LexicalEditorProps>(
  ({
    placeholder,
    onFocus,
    onChange,
    bottomContent,
    hasFocus,
    selectedChannel,
    isInputActive,
    handleSendMessage,
    pluginConfig,
    attachmentList,
    setAttachmentList,
    selectedTab,
    onClear,
    isFullHeight,
    topContent,
    isInputEnabled = true,
    setHasFocus,
    setSelectedTabKey,
    selectedInteraction,
    rightRailLeadingContent,
  }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const hasAttachments = attachmentList.length > 0;
    const editorContainerRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<HTMLDivElement>(null);
    const handleOnFocus = useCallback(() => {
      if (onFocus) {
        onFocus();
      }
    }, [onFocus]);

    const primaryPlugins = useMemo(
      () => (
        <>
          <OnChangePlugin onChange={onChange} />
          <ClearEditorPlugin />
          <TabChangePlugin onClear={onClear} selectedTab={selectedTab} />
          <HistoryPlugin />
          <ReplyDetectorPlugin
            selectedInteraction={selectedInteraction}
            setHasFocus={setHasFocus}
            setSelectedTabKey={setSelectedTabKey}
          />
          <DesktopSendKeyboardShortcutPlugin onPressed={handleSendMessage} />
        </>
      ),
      [
        handleSendMessage,
        onChange,
        onClear,
        selectedInteraction,
        selectedTab,
        setHasFocus,
        setSelectedTabKey,
      ],
    );

    return (
      <LexicalEditorContainer ref={editorContainerRef}>
        {topContent}
        <LexicalComposer initialConfig={lexicalEditorConfig}>
          <LexicalContainer
            onFocus={handleOnFocus}
            className={c({
              'editor-container': true,
              'full-height': isFullHeight,
            })}
          >
            {isInputEnabled ? (
              <>
                <PlainTextPlugin
                  contentEditable={
                    <TextInputContainer
                      className={c({
                        focused: hasFocus,
                        'full-height': isFullHeight,
                      })}
                      ref={editorRef}
                      data-lgg-id="contact-interaction-message-input"
                    >
                      <Scrollbar>
                        <ContentEditable className="editor-input" />
                      </Scrollbar>
                    </TextInputContainer>
                  }
                  placeholder={
                    <TextInputContainer
                      className={c({
                        focused: hasFocus,
                        'editor-placeholder': true,
                      })}
                    >
                      {placeholder}
                    </TextInputContainer>
                  }
                  ErrorBoundary={LexicalErrorBoundary}
                />
                {primaryPlugins}
                {Boolean(pluginConfig.messageAttachments) && (
                  <>
                    <PasteDnDFilePlugin
                      onFileDropped={(files) => {
                        const filesWithId = files.map((file) => ({
                          ...file,
                          id: uuid(),
                          isRecentFile: false,
                        }));
                        setAttachmentList([...attachmentList, ...filesWithId]);
                      }}
                    />
                    {hasAttachments && selectedChannel && (
                      <MessageAttachments
                        attachments={attachmentList}
                        selectedChannel={selectedChannel}
                        setAttachmentList={setAttachmentList}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              primaryPlugins
            )}
            {isInputEnabled && (
              <BottomBarContainer
                className={c({
                  unfocused: !hasFocus && isInputEnabled,
                  visible: bottomContent.visible,
                })}
              >
                <LeftRail>
                  {bottomContent?.leading && bottomContent.leading}
                  {isInputEnabled ? (
                    <>
                      {breakpointUpMd && pluginConfig.emojiPicker && (
                        <EmojiPickerPlugin />
                      )}
                      {Boolean(pluginConfig.messageAttachments) && (
                        <ContactInteractionsInputAreaAttachmentOptions
                          setAttachmentList={setAttachmentList}
                        />
                      )}
                      {Boolean(pluginConfig.mentions) && (
                        <MentionsPlugin
                          editorContainerRef={editorContainerRef}
                          editorRef={editorRef}
                        />
                      )}
                      {Boolean(pluginConfig.aiTools) && <AiToolsPlugin />}
                      {bottomContent?.trailing && bottomContent.trailing}
                    </>
                  ) : null}
                </LeftRail>
                <RightRail>
                  {isInputEnabled ? (
                    <>
                      {Boolean(rightRailLeadingContent) && rightRailLeadingContent}
                      <SendIconButton
                        isInputActive={isInputActive}
                        onClick={handleSendMessage}
                      />
                    </>
                  ) : null}
                </RightRail>
              </BottomBarContainer>
            )}
          </LexicalContainer>
        </LexicalComposer>
      </LexicalEditorContainer>
    );
  },
);
