import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import {
  ProfileSettingsProvider,
  useProfileSettings,
} from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import { ProfileSettingsModalDesktopView } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal.desktop-view';
import { ProfileSettingsModalMobileView } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal.mobile-view';
import { MenuItem } from 'src/components/layout/authenticated-layout/user-account-menu';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export type BaseFormProps = {
  formId: string;
};

type ProfileSettingsProps = {
  key: number;
  onMenuItemClick: () => void;
};

type ProfileSettingsModalProps = ProfileSettingsProps;

export const ProfileSettings: React.FC<ProfileSettingsModalProps> = (props) => (
  <ProfileSettingsProvider>
    <ProfileSettingsModal {...props} />
  </ProfileSettingsProvider>
);

const ProfileSettingsModal = memo<ProfileSettingsModalProps>(
  ({ onMenuItemClick, ...props }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const { visibilityHandler } = useProfileSettings();
    const { t } = useTranslation(['user']);

    const handleClick = () => {
      onMenuItemClick?.();
      visibilityHandler.show();
    };

    return (
      <>
        <MenuItem
          data-lgg-id="menu-item-profile-settings"
          {...props}
          onClick={handleClick}
          icon="gear"
          label={t('user:profileSettings.title')}
        />
        {visibilityHandler.visible &&
          (breakpointUpMd ? (
            <ProfileSettingsModalDesktopView />
          ) : (
            <ProfileSettingsModalMobileView />
          ))}
      </>
    );
  },
);
